<div class="form-input" title="">
  <div class="mb-10px">
    <strong>{{ title | translate }}<span class="required" *ngIf="required">*</span></strong>
  </div>
  <mat-form-field appearance="outline">
    <!-- Use numbersOnly directive if type is a 'number' -->
    <input *ngIf="type === 'number'" matInput type="text" [autocomplete]="autocomplete" [formControl]="control" [errorStateMatcher]="errorStateMatcher"
      (input)="onChange($event)" (blur)="blurred.emit()" placeholder="{{ placeholder | translate }}" [mask]="mask"
      onfocus="removeAttribute('readonly');" ontouchstart="removeAttribute('readonly');" readonly numbersOnly />

    <input *ngIf="type !== 'number'" matInput [type]="type" [autocomplete]="autocomplete" [formControl]="control" [errorStateMatcher]="errorStateMatcher"
      (input)="onChange($event)" (blur)="blurred.emit()" placeholder="{{ placeholder | translate }}" [mask]="mask"
      onfocus="removeAttribute('readonly');" ontouchstart="removeAttribute('readonly');" readonly />

    <mat-error class="body-small-header" *ngFor="let error of errors" [translate]="error"></mat-error>
  </mat-form-field>
</div>
