export class Errors {

  public static todo(str: string): never {
    throw new Error(`Not implemented: ${str}`)
  }
  public static illegalArgument(...messages: any[]): never {
    const msg = Errors.getFullMessage('Illegal Argument Error: ', ...messages)
    throw new Error(msg)
  }

  public static illegalState(...messages: any[]): never {
    const msg = Errors.getFullMessage('Illegal State Error: ', ...messages)
    throw new Error(msg)
  }


  private static getFullMessage(messageCode: string, ...messages: any[]): string {
    const allMessages = [messageCode, ...messages].map(it => it.toString())
    return allMessages.join(' ')
  }
}
