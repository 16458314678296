export const Breakpoints = {
  xs: '(max-width: 767px)',
  sm: '(min-width: 768px)',
  sm_only: '(min-width: 768px) and (max-width: 1023px)',
  md: '(min-width: 1024px)',
  md_only: '(min-width: 1024px) and (max-width: 1439px)',
  lg: '(min-width: 1440px)',
  lg_only: '(min-width: 1440px) and (max-width: 1919px)',
  xl: '(min-width: 1920px)',
};
