import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { Logger } from './app/utils/logger';
import { environment } from './environments/environment';

/**
 * Polyfill applied to have `String.replaceAll` on the Citrix Environment of Evida
 */
function stringReplaceAllPoly() {
  if (!String.prototype.replaceAll) {
    // eslint-disable-next-line space-before-function-paren
    String.prototype.replaceAll = function (str, newStr) {

      // If a regex pattern
      if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
        return this.replace(str, newStr);
      }

      // If a string
      return this.replace(new RegExp(str, 'g'), newStr);

    };
  }
}

// Apply extra polyfills
const applyMorePolyfills = () => {
  stringReplaceAllPoly();
}

applyMorePolyfills();



// Remove leftovers from dev site.
localStorage.removeItem('__debug_AnnualFlowTracking');

// The injector has not been created yet so we need to create an instance ourselves
const log = new Logger(environment.loggingConfig);

/*
 * Adds Simple Analytics script to the head
 * Will add the dev script if the environment is local or mock, and will use
 * localhost-mit.evida.dk as hostname instead of localhost
 */
const addSimpleAnalytics = () => {
  const simpleAnalyticsScript = document.createElement('script');
  simpleAnalyticsScript.defer = true;

  if (environment.envName === 'local' || environment.envName === 'mock') {
    simpleAnalyticsScript.setAttribute('src', 'https://scripts.simpleanalyticscdn.com/latest.dev.js');
    simpleAnalyticsScript.setAttribute('data-hostname', 'localhost-mit.evida.dk');
  } else {
    simpleAnalyticsScript.setAttribute('src', 'https://scripts.simpleanalyticscdn.com/latest.js');
  }

  document.head.appendChild(simpleAnalyticsScript);
};

addSimpleAnalytics();

log.alwaysLog(
  `%cMit Evida Software
– Env: ${environment.envName}
– Software Version: ${environment.softwareVersion}`,
  'color: blue; font-size: 16px'
);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => log.alwaysLogError(err));
