import { v4 as uuidv4 } from 'uuid'

export class UserSessionIdService {
  public static userSessionStorageKey = 'Storage_MitEvida_UserSessionId'
  public static userSessionHeaderKey = 'X-MitEvida-UserSessionId'
  public static userSessionQueryKey = 'userSessionId'

  public static getSessionId(): string {
    let uuidOrNull = sessionStorage.getItem(this.userSessionStorageKey)
    if (uuidOrNull == null) {
      uuidOrNull = uuidv4()
      sessionStorage.setItem(this.userSessionStorageKey, uuidOrNull)
    }

    return uuidOrNull
  }

  public static resetSessionId(): void {
    sessionStorage.removeItem(this.userSessionStorageKey)
  }
}
