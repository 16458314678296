import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { LoginRoutes } from 'src/app/login/models/login-routes.model'

@Component({
  selector: 'app-site-error-login',
  templateUrl: './site-error-login.component.html',
  styleUrls: ['./site-error-login.component.scss'],
})
export class SiteErrorLoginComponent {
  constructor(private router: Router) { }

  public loginPage() {
    window.location.href = LoginRoutes.login
  }
}
