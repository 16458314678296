/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReadingAddress } from './readingAddress';
import { MonthlyReadingReceiptLine } from './monthlyReadingReceiptLine';


/**
 * Contains the data needed to render receipt in a frontend application
 */
export interface MonthlyReadingReceipt { 
    /**
     * Id of receipt. Needed for obtaining the generated PDF file
     */
    id: string;
    address: ReadingAddress;
    lines: Array<MonthlyReadingReceiptLine>;
}

