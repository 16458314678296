export const LoginRoutes = {
  login: 'login',
  logout: 'logout',
  error: 'error-login',
  flow: {
    root: 'flow',
    private: 'private',
    business: 'business',
    credentialsBusiness: 'evida'
  },
  webaccess: 'webaccess/:type'
}
