import { Injectable, inject } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject, Observable, catchError, firstValueFrom, map, of } from 'rxjs'
import { SessionStorageService } from 'src/app/login/services/session-storage.service'
import { AuthService } from 'src/app/shared/auth/auth.service'
import { ReponseErrors } from 'src/app/soft-login/models/error.model'
import { RedirectTo } from 'src/app/utils/redirect-to'
import { Utils } from 'src/app/utils/utils'
import {
  BusinessProfileService as ApiBusinessProfileService
} from '../../../shared/services/backend'

@Injectable({
  providedIn: 'root'
})

export class ProductionEquipmentAccessService {
  private authService = inject(AuthService)
  private sessionStorageService = inject(SessionStorageService)
  private profileService = inject(ApiBusinessProfileService)
  private router = inject(Router)

  private productionEquipment: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public productionEquipment$: Observable<boolean> = this.productionEquipment.asObservable()
  public loading$: Observable<boolean> = of(false)

  public async getProductionEquipmentAccess() {
    if (!this.authService.isPrivateCustomer) {
      this.loading$ = of(!this.router.url.includes('profile')) // Set loader to true if not Profile page
      let response = await firstValueFrom(this.productionEquipmentAccess$)
      this.productionEquipment.next(response)
      this.loading$ = of(false)
    } else {
      this.productionEquipment.next(false)
    }
  }

  private get productionEquipmentAccess$(): Observable<boolean> {
    let customerNumber = this.sessionStorageService.customerNumber
    let installationNumber = this.sessionStorageService.installationNumber

    if (customerNumber && installationNumber) {
      return this.profileService.
        apiProfileBusinessCustomerCustomerNumberProductionConsumptionInstallationNumberGet(
          customerNumber,
          installationNumber
        )
        .pipe(
          map(i => i.hasProductionEquipment)
        )
        .pipe(
          catchError(error => {
            this.handleError(error)
            return of(false)
          })
        )
    } else {
      return of(false)
    }
  }
  
  private handleError(error) {
    const extras = {
      state: {
        errorCode: ReponseErrors.INTERNAL_SERVER_ERROR,
        correlationId: Utils.getCorrelationIdFromError(error),
        errorInfo: error.message,
        url: this.router.url
      }
    }
    RedirectTo.internalServerError(this.router, extras)
  }
}
