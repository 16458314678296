/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SigningStatus = 'Active' | 'Created' | 'Expired' | 'Cancelled' | 'Completed' | 'FinalizationPending';

export const SigningStatus = {
    Active: 'Active' as SigningStatus,
    Created: 'Created' as SigningStatus,
    Expired: 'Expired' as SigningStatus,
    Cancelled: 'Cancelled' as SigningStatus,
    Completed: 'Completed' as SigningStatus,
    FinalizationPending: 'FinalizationPending' as SigningStatus
};

