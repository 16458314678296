import { registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import localeDa from '@angular/common/locales/da'
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TranslateLoader, TranslateModule, TranslateModuleConfig } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { environment } from 'src/environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AuthConfigModule } from './login/configuration/auth-config.module'
import { MaterialModule } from './material.module'
import { RootComponent } from './root/root.component'
import { AuthInterceptor } from './shared/auth/auth.interceptor'
import { AuthService } from './shared/auth/auth.service'
import { ErrorInterceptor } from './shared/auth/error.interceptor'
import { QueueItInterceptor } from './shared/auth/queue-it.interceptor'
import { ApiCacheInterceptor } from './shared/interceptors/api-cache.interceptor'
import { Features } from './shared/models/features.model'
import { ApiModule, BASE_PATH } from './shared/services/backend'
import { QueueItLoaderService } from './shared/services/queueit-loader.service'
import { SharedModule } from './shared/shared.module'
import { ShortLinkComponent } from './short-link/short-link.component'
import { EvidaScreenNameProvider } from './tracking/evida-screens'
import { UserSessionInterceptor } from './user-session/interceptors/user-session.interceptor'
import { UserSessionComponent } from './user-session/user-session/user-session.component'
import { Logger, LoggerConfig } from './utils/logger'

export const translateModuleConfig: TranslateModuleConfig = {
  defaultLanguage: 'da',
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
}
registerLocaleData(localeDa)

// eslint-disable-next-line
export function queueItLoaderFactory(environmentSettingsLoaderService: QueueItLoaderService) {
  return () => environmentSettingsLoaderService.load()
}

// eslint-disable-next-line
export function checkAuthFactory(authService: AuthService) {
  return () => authService.checkAuth
}

// AoT requires an exported function for factories
// eslint-disable-next-line
export function HttpLoaderFactory(http: HttpClient) {
  // We shall use the suffix IF the current environment IS NOT 'mock' NOR 'local'
  const envNameArray = ['mock', 'local']
  if (window.location.hostname === 'localhost') {
    envNameArray.push('dev')
  }
  const useSuffix = !envNameArray.includes(environment.envName)
  const prefix = undefined
  const suffix = useSuffix ? `.${environment.softwareVersion}.json` : undefined
  return new TranslateHttpLoader(http, prefix, suffix)
}

@NgModule({
  declarations: [RootComponent, ShortLinkComponent, UserSessionComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ApiModule,
    HttpClientModule,
    SharedModule,
    MaterialModule,
    AuthConfigModule,
    TranslateModule.forRoot(translateModuleConfig),
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.API_BASE_PATH },
    { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'da-DK' },
    { provide: LOCALE_ID, useValue: 'da-DK' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: QueueItInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiCacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UserSessionInterceptor, multi: true},
    { provide: LoggerConfig, useValue: environment.loggingConfig },
    { provide: Logger, deps: [LoggerConfig] },
    { provide: EvidaScreenNameProvider, deps: [Logger] },
    { provide: Features, useValue: environment.features },
    QueueItLoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: queueItLoaderFactory,
      deps: [QueueItLoaderService],
      multi: true,
    },
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: checkAuthFactory,
      deps: [AuthService],
      multi: true,
    },
  ],
  bootstrap: [RootComponent],
})
export class AppModule { }

