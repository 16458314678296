export enum CardType {
  gas = 'gas',
  electricity = 'electricity',
  heat = 'heat',
}

export enum CardUnitType {
  m3 = 'm3',
  nm3 = 'nm3',
  ucm3 = 'ucm3',
  vprod = 'vprod',
  eprod = 'eprod',
}
