/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ForgotDetailsRequest { 
    email?: string | null;
    phoneNumber?: string | null;
    street?: string | null;
    /**
     * House number. e.g. \"13\", or \"22 B\"
     */
    houseNumber?: string | null;
    zipCode?: string | null;
    /**
     * Optional field
     */
    customerNumber?: string | null;
}

