/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a single reading line
 */
export interface ReadingLineReceipt { 
    meterNumber?: string | null;
    reading?: number;
    readingUnit?: string | null;
    consumption?: number | null;
}

