import { FocusMonitor } from '@angular/cdk/a11y'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core'
import { AbstractControl, ControlValueAccessor, NgControl, UntypedFormBuilder } from '@angular/forms'
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field'
import { Subject, Subscription } from 'rxjs'
import { Utils } from 'src/app/utils/utils'

@Component({
  selector: 'app-reading-line',
  templateUrl: './reading-line.component.html',
  styleUrls: ['./reading-line.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: ReadingLineComponent }],
})
export class ReadingLineComponent
implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor, MatFormFieldControl<number>
{
  private static nextId = 0

  // Ignoring this because these were just following the instructions from the Material guide
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') public userAriaDescribedBy: string
  @Input() public showWarning = false
  @Input() public maxInputDigits = 6
  @Input() public hint: string | null = null
  @Input() public deviationText: string
  @Input() public showSample = false
  /**
   * Object used too look up an error coming from the parent component:
   *
   * @example
   * <app-reading-line [control]="control" [errorLookup]="{ required: 'This field is required' }">
   * </app-reading-line>
   *
   * if your control looks like
   * control = this.fb.control('', [Validators.required])
   */
  @Input() public errorLookup: { [key: string]: string } = {}
  @HostBinding() id = `reading-line-${ReadingLineComponent.nextId++}`

  public focused = false
  // Implementation of interface MatFormFieldControl
  public stateChanges = new Subject<void>()
  public errorState = false
  public controlType = 'reading-line'
  public unitText: string
  public unitSuperindex: string

  private _control: AbstractControl = this.fb.control('')
  private _unit: string
  private subscriptions: Subscription | null = null
  private _required = false
  private _disabled = false
  private _placeholder: string
  private cvaFields = {
    onChangeFn: (_val: any) => { },
    onTouchFn: () => { },
  }

  constructor(
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl,
    private fb: UntypedFormBuilder
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this
    }
  }

  @HostBinding('class.floating')
  public get shouldLabelFloat() {
    return this.focused || !this.empty
  }

  public get errors(): Array<string> {
    const validationErrors = this.control?.errors ?? {}
    const keys = Object.keys(validationErrors)
    return keys.map((key) => this.errorLookup[key])
  }

  public get mask(): string | null {
    return '9'.repeat(this.maxInputDigits)
  }

  public get empty(): boolean {
    const v = this.value
    return v === null || v === undefined
  }

  
  public get control(): AbstractControl {
    return this._control
  }
  @Input() public set control(v: AbstractControl) {
    if (this._control === v) {
      return
    }
    this._control = v
  }

  
  public get unit(): string {
    return this._unit
  }
  @Input() public set unit(v: string) {
    this._unit = v
    const [text, index] = Utils.extractSubindex(v)
    this.unitText = text
    this.unitSuperindex = index
  }

  
  public get value(): number {
    const str = this.control?.value || ''
    return Number.parseInt(str, 10)
  }
  @Input() public set value(v: number) {
    if (v !== this.value) {
      this.control?.setValue(v)
      this.cvaFields.onChangeFn?.(v)
      this.stateChanges.next()
    }
  }

  
  public get required(): boolean {
    return this._required
  }
  @Input() public set required(req: boolean) {
    this._required = req
    this.stateChanges.next()
  }

  
  public get placeholder() {
    return this._placeholder
  }
  @Input() public set placeholder(plh: string) {
    this._placeholder = plh
    this.stateChanges.next()
  }

  
  public get disabled(): boolean {
    return this._disabled
  }
  @Input() public set disabled(dis: boolean) {
    this._disabled = dis
    if (dis) this.control?.disable()
    else this.control?.enable()
    this.stateChanges.next()
  }

  ngOnInit() {
    const focusSubscription = this.fm.monitor(this.elRef, true).subscribe((origin) => {
      this.focused = !!origin
      this.stateChanges.next()
    })

    this.subscriptions = new Subscription()
    this.subscriptions.add(focusSubscription)
  }

  ngOnDestroy() {
    this.subscriptions?.unsubscribe()
    this.stateChanges.complete()
  }

  public ngAfterViewInit(): void {
    // This is a workaround because the specificity of the CSS selectors from material
    // Takes priority over my selectors when I write SASS
    // It's specially problematic because I need to apply styles to the div that material generated for me
    const parentsOfUnitLabels = document.querySelectorAll('div.mat-form-field-suffix')
    parentsOfUnitLabels.forEach((element) =>
      element.setAttribute('style', 'align-self: center !important; top: 0 !important;')
    )
  }

  // Impl ControlValueAccessor
  public writeValue(value: any) {
    this.control.setValue(value)
    this.stateChanges.next()
  }

  public registerOnChange(fn: any) {
    this.cvaFields.onChangeFn = fn
  }

  public registerOnTouched(fn: any) {
    this.cvaFields.onTouchFn = fn
  }

  public setDisabledState(value: any) {
    this.disabled = coerceBooleanProperty(value)
  }

  // Impl of MatFormField
  public setDescribedByIds(ids: string[]) {
    const controlElement = this.elRef.nativeElement.querySelector('input')
    controlElement?.setAttribute('aria-describedby', ids.join(' '))
  }

  public onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus()
    }
  }

  public onBlur(): void {
    this.cvaFields.onTouchFn?.()
  }
}
