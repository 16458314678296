import { HttpEvent, HttpRequest } from '@angular/common/http'
import { HttpHandler } from '@angular/common/http'
import { HttpInterceptor } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

/**
 * An http interceptor that sets HTTP headers to disable browser-side cache
 * when the request is pointed to the REST API.
 */
@Injectable()
export class ApiCacheInterceptor implements HttpInterceptor  {

  public intercept(req: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
    return handler.handle(
      this.isApiRequest(req)
        // set cache headers if it's an API request
        ? this.setCacheHeaders(req)
        // Forward the regular call if it's not
        : req
    )
  }

  public isApiRequest(req: HttpRequest<any>): boolean {
    return req.urlWithParams.includes('/api/')
  }

  public setCacheHeaders(req: HttpRequest<any>): HttpRequest<any> {
    const newHeaders =
        req.headers // don't forget to get the previous headers
          .set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache')
          .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    return req.clone({
      headers: newHeaders
    })
  }
}
