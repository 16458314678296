<h2 class="header-medium px-medium mt-medium">{{ title }}</h2>
<p class="px-medium mt-medium body-copy">{{ descripton }}</p>
<div *ngIf="messages?.pre" class="flex flex-col px-medium mt-medium">
  <div class="w-full body-copy">{{ messages?.pre }}</div>
</div>
<mat-card class="p-medium mt-medium">
  <mat-card-content class="flex-wrap justify-between flex">
    <div id="video-container" class="overlay-container w-full sm:w-3/4" (window:resize)="onResize($event)">
      <iframe
        #video
        [width]="videoWidth"
        [height]="videoHeight"
        src="https://player.vimeo.com/video/828258738?dnt=1"
        frameborder="0"
        allow="fullscreen"
        [ngClass]="{ hidden: (iframeHasErrors$ | async) }"
      ></iframe>

      <app-evida-error
        [showRetryButton]="false"
        class="w-full h-full"
        [style.width.px]="videoWidth"
        [style.height.px]="videoHeight"
        [ngClass]="{ hidden: !(iframeHasErrors$ | async) }"
      >
        <h2 [innerHTML]="'flows.reading.howTo.videoFailed.title' | translate"></h2>
        <p
          [innerHTML]="
            'flows.reading.howTo.videoFailed.description'
              | translate: { video_href: 'https://player.vimeo.com/video/828258738' }
          "
        ></p>
      </app-evida-error>
    </div>
    <!-- We can't use mt-small because it's already responsive -->
    <div class="xs:mt-10px sm:mt-0 w-full sm:w-1/4 xs:pl-0 sm:pl-20px">
      <p class="body-small m-0" [innerHTML]="videoSideText | translate"></p>
      <p
        class="body-small mt-10px"
        *ngIf="gasReading.readings.length > 1"
        [innerHtml]="'flows.reading.input.getHelp' | translate"
      ></p>
    </div>
  </mat-card-content>

  <div class="flex flex-col-reverse sm:flex-row justify-between mt-medium">
    <app-evida-button
      class="w-full sm:w-auto"
      type="secondary"
      (clicked)="backClicked()"
      [style.visibility]="hasMoreReadingCards ? 'visible' : 'hidden'"
      [ngClass]="{ hideInXS: (isXS$ | async) && !hasMoreReadingCards }"
    >
      {{ 'shared.buttons.back' | translate }}
    </app-evida-button>
    <app-evida-button class="w-full sm:w-auto mb-10px sm:mb-0" type="primary" (clicked)="continueClicked()">
      {{ 'shared.buttons.continue' | translate }}
    </app-evida-button>
  </div>
</mat-card>

<div *ngIf="messages?.post" class="flex flex-col px-medium mt-medium" [ngClass]="{ isXS: (isXS$ | async) }">
  <div class="w-full body-copy">
    {{ messages?.post }}
  </div>
</div>

<div *ngIf="shouldShowWrongReceiverFlow()" class="mt-medium px-0 w-full sm:w-1/2">
  <app-evida-banner noIcon="true">
    <p class="mt-5px">Er du ikke længere ansvarlig for aflæsning på adressen?</p>
    <app-evida-button class="w-full sm:w-auto mt-20px" type="quaternary" (clicked)="goToWrongReceiver()">
      <span class="text-petroleum body-small-header">Giv os besked</span>
    </app-evida-button>
  </app-evida-banner>
</div>
