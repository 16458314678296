/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReadingReceipt } from './readingReceipt';
import { MeterReadingValidationStatus } from './meterReadingValidationStatus';


export interface ReadingResponse { 
    validationStatus: MeterReadingValidationStatus;
    /**
     * The ReceiptId will only be set if the ValidationStatus is \'Valid\'
     */
    receiptId?: string | null;
    receiptContent?: ReadingReceipt;
}

