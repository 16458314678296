/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DiscrepancyReasonEnum = 'EnergyRenovation' | 'OtherHeatingSource' | 'ChangeInUsage' | 'AdditionalHeatingSource' | 'MeterStopped' | 'DoNotKnow';

export const DiscrepancyReasonEnum = {
    EnergyRenovation: 'EnergyRenovation' as DiscrepancyReasonEnum,
    OtherHeatingSource: 'OtherHeatingSource' as DiscrepancyReasonEnum,
    ChangeInUsage: 'ChangeInUsage' as DiscrepancyReasonEnum,
    AdditionalHeatingSource: 'AdditionalHeatingSource' as DiscrepancyReasonEnum,
    MeterStopped: 'MeterStopped' as DiscrepancyReasonEnum,
    DoNotKnow: 'DoNotKnow' as DiscrepancyReasonEnum
};

