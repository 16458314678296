import { Features } from './../models/features.model'
import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core'

/**
 * Directive that shall be used with the asterisk like if it was an ngIf.
 * It supports booleans and strings as input parameters and it'll check
 * whether the annotated markup is enabled or disabled
 */
@Directive({
  // eslint-disable-next-line  @angular-eslint/directive-selector
  selector: '[enableFeature]'
})
export class EnableFeatureDirective {

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private features: Features
  ) {
  }

  @Input()
  public set enableFeature(val: boolean | string) {

    let enabled = false

    switch (true) {
    case typeof val === 'boolean':
      enabled = val as boolean
      break
    case typeof val === 'string':
      enabled = this.getFeatureByKey(this.features, val as string)
      break
    default:
      throw new Error(
        `IllegalArgument error on enable-feature-directive. Expecting boolean or string but got ${val}`)
    }
    this.keepOrHide(enabled)
  }


  private keepOrHide(flag: boolean) {
    if (flag) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    } else {
      this.viewContainer.clear()
    }
  }

  private getFeatureByKey(sourceObject: any, key: string): boolean {
    // Check if expression is negated
    const isNegated = key.startsWith('!')
    // Remove ! because it doesn't belong to the object keys
    const actualKey = isNegated ? key.substring(1, key.length) : key

    // Here we break the potentially nested keys into an array
    // prop1.prop2 => ["prop1", "prop2"]
    const nestedKeys = actualKey.split('.').reverse()
    // Check it's an empty prop name
    if (nestedKeys.length === 1 && nestedKeys[0].length === 0) { return false }

    let obj = null
    do {
      const nextKey = nestedKeys.pop()
      obj = obj == null ? sourceObject[nextKey] : obj[nextKey]
      // Key didn't exist so we cannot iterate over it.
      if (obj == null) { break }
    } while (nestedKeys.length > 0)

    return isNegated ? !obj : !!obj
  }
}
