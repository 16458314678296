/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProfileInstallation } from './profileInstallation';


export interface ProfileListItem { 
    /**
     * da: Kundenummer
     */
    customerNumber: string;
    /**
     * 0..n installations associated with the customer relation
     */
    installations: Array<ProfileInstallation>;
}

