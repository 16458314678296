import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { environment } from 'src/environments/environment'
import { LoginRoutes } from './login/models/login-routes.model'
import ChannelGuard from './shared/auth/channel.guard'
import { MaintenanceComponent } from './shared/components/maintenance/maintenance.component'
import { NoAccessErrorComponent } from './shared/components/no-access-error/no-access-error.component'
import { NotFoundComponent } from './shared/components/not-found/not-found-component'
import { SiteBusyComponent } from './shared/components/site-busy/site-busy-component'
import { SiteErrorLoginComponent } from './shared/components/site-error-login/site-error-login.component'
import { SiteErrorComponent } from './shared/components/site-error/site-error-component'
import { SiteExpiredSessionComponent } from './shared/components/site-expired-session/site-expired-session.component'
import { UserBlockedErrorComponent } from './shared/components/user-blocked-error/user-blocked-error.component'
import { XellentTimeoutComponent } from './shared/components/xellent-timeout/xellent-timeout.component'
import { ShortLinkComponent } from './short-link/short-link.component'
import { UserSessionComponent } from './user-session/user-session/user-session.component'
import { RedirectTo } from './utils/redirect-to'

/**
 * Lazy Loading Feature Modules, since we want to avoid loading sections, that we don't need
 */
export const routes: Routes = [
  {
    path: 'flow',
    canActivate: [ChannelGuard],
    loadChildren: () => import('./soft-login/soft-login.module').then((m) => m.SoftLoginModule),
  },
  {
    path: LoginRoutes.login,
    canActivate: [ChannelGuard],
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'evida',
    loadChildren: () => import('./evida/evida.module').then((m) => m.EvidaModule),
  },
  {
    path: 'aflaesning',
    canActivate: [ChannelGuard],
    loadChildren: () => import('./reading-card/reading-card.module').then((m) => m.ReadingCardModule),
  },
  {
    path: 's/:shortLinkData',
    canActivate: [ChannelGuard],
    component: ShortLinkComponent,
  },
  {
    path: 'id',
    canActivate: [ChannelGuard],
    component: UserSessionComponent
  },
  { path: 'error', canActivate: [ChannelGuard], component: SiteErrorComponent },
  { path: 'blocked', canActivate: [ChannelGuard], component: UserBlockedErrorComponent },
  { path: 'no-access', canActivate: [ChannelGuard], component: NoAccessErrorComponent },
  { path: 'error-login', canActivate: [ChannelGuard], component: SiteErrorLoginComponent },
  { path: 'busy', canActivate: [ChannelGuard], component: SiteBusyComponent },
  { path: 'timeout', canActivate: [ChannelGuard], component: XellentTimeoutComponent },
  { path: 'not-found', canActivate: [ChannelGuard], component: NotFoundComponent },
  { path: 'expired-session', canActivate: [ChannelGuard], component: SiteExpiredSessionComponent },
  environment.features.enableMaintenance ? { path: '**', component: MaintenanceComponent } : { path: '**', redirectTo: RedirectTo.loginRootFlow }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
