<div
  class="evida-bullet p-small mt-small {{ bulletClass }}"
  [ngClass]="{ 'evida-bullet--centered-text': centeredText }"
>
  <div class="evida-bullet_point" *ngIf="content">{{ content }}</div>
  <div class="evida-bullet_point evida-bullet_point--icon" *ngIf="icon">
    <mat-icon class="fg-green" mat-list-icon>{{ icon }}</mat-icon>
  </div>
  <div class="evida-bullet_content pl-medium">
    <ng-content></ng-content>
  </div>
</div>
