/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ValidationErrorCodes = 'Unspecified' | 'AssociateByCredentialsBusinessMissingCvrOnCustomer' | 'AssociateByCredentialsBusinessCvrMismatch' | 'AssociateByCredentialsPrivateCustomerHasCvr' | 'CantSubmitTerminationReadingThisDate' | 'TerminationReadingAlreadyInProgress';

export const ValidationErrorCodes = {
    Unspecified: 'Unspecified' as ValidationErrorCodes,
    AssociateByCredentialsBusinessMissingCvrOnCustomer: 'AssociateByCredentialsBusinessMissingCvrOnCustomer' as ValidationErrorCodes,
    AssociateByCredentialsBusinessCvrMismatch: 'AssociateByCredentialsBusinessCvrMismatch' as ValidationErrorCodes,
    AssociateByCredentialsPrivateCustomerHasCvr: 'AssociateByCredentialsPrivateCustomerHasCvr' as ValidationErrorCodes,
    CantSubmitTerminationReadingThisDate: 'CantSubmitTerminationReadingThisDate' as ValidationErrorCodes,
    TerminationReadingAlreadyInProgress: 'TerminationReadingAlreadyInProgress' as ValidationErrorCodes
};

