import { CommonModule, DatePipe, DecimalPipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'
import { FormatUnitPipe } from '../soft-login/pipes/format-unit.pipe'
import { MaterialModule } from './../material.module'
import { BaseCardComponent } from './components/base-card/base-card.component'
import { DialogComponent } from './components/dialog/dialog.component'
import { EvidaBannerComponent } from './components/evida-banner/evida-banner.component'
import { EvidaBulletComponent } from './components/evida-bullet/evida-bullet.component'
import { EvidaButtonComponent } from './components/evida-button/evida-button.component'
import { EvidaCheckboxComponent } from './components/evida-checkbox/evida-checkbox.component'
import { EvidaErrorComponent } from './components/evida-error/evida-error.component'
import { EvidaFaqComponent } from './components/evida-faq/evida-faq.component'
import { EvidaPhoneNumberComponent } from './components/evida-phone-number/evida-phone-number.component'
import { EvidaStepperComponent } from './components/evida-stepper/evida-stepper.component'
import { EvidaSwitchComponent } from './components/evida-switch/evida-switch.component'
import { FooterComponent } from './components/footer/footer.component'
import { FormInputComponent } from './components/form-input/form-input.component'
import { HowToComponent } from './components/how-to/how-to.component'
import { InfoMessageComponent } from './components/info-message/info-message.component'
import { InstallationCellComponent } from './components/installation-cell/installation-cell.component'
import { LoaderComponent } from './components/loader/loader.component'
import { MaintenanceComponent } from './components/maintenance/maintenance.component'
import { BurgerMenuComponent } from './components/menu/burger-menu/burger-menu.component'
import { VerticalMenuComponent } from './components/menu/vertical-menu.component'
import { NoAccessErrorComponent } from './components/no-access-error/no-access-error.component'
import { NotFoundComponent } from './components/not-found/not-found-component'
import { ProfileNavItemComponent } from './components/profile-nav-item/profile-nav-item.component'
import { ReadingLineComponent } from './components/reading-line/reading-line.component'
import { ReadingStepComponent } from './components/reading-step/reading-step.component'
import { SearchComponent } from './components/search/search.component'
import { SimpleMessageComponent } from './components/simple-message/simple-message.component'
import { SiteBusyComponent } from './components/site-busy/site-busy-component'
import { SiteErrorLoginComponent } from './components/site-error-login/site-error-login.component'
import { SiteErrorComponent } from './components/site-error/site-error-component'
import { SiteExpiredSessionComponent } from './components/site-expired-session/site-expired-session.component'
import { ToolbarComponent } from './components/toolbar/toolbar.component'
import { TooltipComponent } from './components/tooltip/tooltip.component'
import { UserBlockedErrorComponent } from './components/user-blocked-error/user-blocked-error.component'
import { XellentTimeoutComponent } from './components/xellent-timeout/xellent-timeout.component'
import { CITIES_BY_ZIPCODE_DEFAULT_PROVIDER } from './data/citiesByZipcode'
import { ClickOutsideDirective } from './directives/click-outside.directive'
import { EnableFeatureDirective } from './directives/enable-feature.directive'
import { NumberDirective } from './directives/only-number.directive'
import { RequiredDirective } from './directives/required.directive'
import { TooltipDirective } from './directives/tooltip.directive'
import { FeatureGuard } from './guards/feature.guard'
import { Masks } from './models/masks'
import { Patterns } from './models/patterns.model'
import { CommaSeparatedPipe } from './pipes/comma-separated.pipe'
import { EmptyFieldPipe } from './pipes/empty-field.pipe'
import { DialogService } from './services/dialog.service'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    RouterModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    CITIES_BY_ZIPCODE_DEFAULT_PROVIDER,
    DialogService,
    FeatureGuard,
    Patterns,
    Masks,
    DatePipe,
    DecimalPipe,
    CommaSeparatedPipe,
    FormatUnitPipe,
    EmptyFieldPipe,
    provideNgxMask()
  ],
  exports: [
    InstallationCellComponent,
    XellentTimeoutComponent,
    SimpleMessageComponent,
    EvidaPhoneNumberComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    EvidaStepperComponent,
    FormsModule,
    ReactiveFormsModule,
    ToolbarComponent,
    VerticalMenuComponent,
    BurgerMenuComponent,
    FooterComponent,
    EvidaBannerComponent,
    EvidaButtonComponent,
    EvidaBulletComponent,
    HowToComponent,
    EvidaFaqComponent,
    ProfileNavItemComponent,
    TranslateModule,
    EvidaSwitchComponent,
    EvidaCheckboxComponent,
    EvidaErrorComponent,
    LoaderComponent,
    NotFoundComponent,
    SiteErrorComponent,
    SiteErrorLoginComponent,
    SiteBusyComponent,
    EnableFeatureDirective,
    NumberDirective,
    RequiredDirective,
    ClickOutsideDirective,
    ReadingStepComponent,
    ReadingLineComponent,
    FormInputComponent,
    DialogComponent,
    TooltipComponent,
    TooltipDirective,
    BaseCardComponent,
    InfoMessageComponent,
    CommaSeparatedPipe,
    FormatUnitPipe,
    EmptyFieldPipe,
    MaintenanceComponent
  ],
  declarations: [
    InstallationCellComponent,
    NoAccessErrorComponent,
    UserBlockedErrorComponent,
    XellentTimeoutComponent,
    SimpleMessageComponent,
    EvidaPhoneNumberComponent,
    EnableFeatureDirective,
    NumberDirective,
    RequiredDirective,
    ClickOutsideDirective,
    EvidaStepperComponent,
    ToolbarComponent,
    EvidaCheckboxComponent,
    EvidaErrorComponent,
    SearchComponent,
    VerticalMenuComponent,
    BurgerMenuComponent,
    FooterComponent,
    EvidaButtonComponent,
    EvidaBulletComponent,
    HowToComponent,
    EvidaFaqComponent,
    ProfileNavItemComponent,
    EvidaSwitchComponent,
    LoaderComponent,
    NotFoundComponent,
    SiteErrorComponent,
    SiteErrorLoginComponent,
    SiteBusyComponent,
    EvidaBannerComponent,
    ReadingStepComponent,
    ReadingLineComponent,
    FormInputComponent,
    DialogComponent,
    TooltipComponent,
    TooltipDirective,
    BaseCardComponent,
    InfoMessageComponent,
    CommaSeparatedPipe,
    FormatUnitPipe,
    EmptyFieldPipe,
    MaintenanceComponent,
    SiteExpiredSessionComponent
  ],
})
export class SharedModule { }
