
export enum ReponseErrors {
  DECOPLING_UNAUTHORIZED = 'DECOPLING_UNAUTHORIZED',
  SESSION_TIMEOUT = 'SESSION_TIMEOUT',
  METER_READINGS_NOT_FOUND = 'METER_READINGS_NOT_FOUND',
  VERIFICATION_CODE_SESSION_TIMEOUT = 'VERIFICATION_CODE_SESSION_TIMEOUT',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  INSTALLATIONS_NOT_FOUND = 'INSTALLATIONS_NOT_FOUND',
  CANT_SUBMIT_TERMINATION_READING_THIS_DATE = 'CANT_SUBMIT_TERMINATION_READING_THIS_DATE'
}

export interface ErrorState {
  errorCode: number | ReponseErrors;
  errorInfo: string;
}
