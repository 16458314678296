import { UserIdProvider } from './user-id-provider'

export class EvidaUserIdProvider implements UserIdProvider {

  private static _instance: EvidaUserIdProvider | null = null
  private static googleAnalyticsUniqueIdStorageKey = '__ga__mitevida_user_uniqueid'
  private static readonly storage: Storage = localStorage

  private userId: string | null = null

  private constructor() {
    this.userId = this.getOrGenerateUUID()
  }

  public static get(): EvidaUserIdProvider {
    if (this._instance === null) {
      this._instance = new EvidaUserIdProvider()
    }
    return this._instance
  }

  /**
   * Implementation found from [here](https://stackoverflow.com/a/873856/9248718)
   */
  private static generateUUIDV4(): string {
    // http://www.ietf.org/rfc/rfc4122.txt
    const s = []
    const hexDigits = '0123456789abcdef'
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4'  // bits 12-15 of the time_hi_and_version field to 0010
    // eslint-disable-next-line no-bitwise
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-'

    const uuid = s.join('')
    return uuid
  }

  public getUserId(): string {
    return this.userId
  }

  /**
   * Fetches from disk the UUID. If it doesn't exist, creates one.
   */
  private getOrGenerateUUID(): string {
    const storageKey = EvidaUserIdProvider.googleAnalyticsUniqueIdStorageKey
    const storage = EvidaUserIdProvider.storage
    let uuidOrNull = storage.getItem(storageKey)
    if (uuidOrNull == null) {
      uuidOrNull = EvidaUserIdProvider.generateUUIDV4()
      storage.setItem(storageKey, uuidOrNull)
    }
    return uuidOrNull
  }

}
