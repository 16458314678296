<div class="mt-10px flex flex-row flex-wrap justify-center xs:justify-start">
  <div class="flex-auto">
    <mat-form-field class="w-full" appearance="outline" [ngClass]="{ 'mat-form-field-warn': showWarning }">
      <input
        matInput
        [id]="id"
        [name]="id"
        [formControl]="control"
        type="text"
        class="header-medium gas-input-spacing"
        autocomplete="off"
        [mask]="mask"
        [specialCharacters]="[]"
      />
      <span class="header-x-small whitespace-no-wrap tracking-tightest" matSuffix *ngIf="unit">
        {{ unitText }} <sup>{{ unitSuperindex }}</sup>
      </span>
    
      <mat-hint>
        <div
          *ngIf="showWarning"
          class="body-small-header text-orange small"
          [innerHTML]="deviationText"
        ></div>
      </mat-hint>
    
      <mat-error *ngFor="let err of errors">
        <span class="body-small-header">{{ err }}</span>
      </mat-error>
    </mat-form-field>
    <div class="body-small input-help" *ngIf="hint && hint.length > 0" [innerHTML]="hint"></div>
  </div>

  <div
    class="flex-row w-full justify-center sm:w-1/2 sm:justify-start pt-10px sm:pt-0"
    [ngClass]="{ flex: showSample, hidden: !showSample }"
  >
    <div class="reading-sample w-285px sm:max-w-100pct mt-20px xs:mt-0 xs:px-20px">
      <mat-icon inline="true" svgIcon="evida_gas_counter"></mat-icon>
    </div>
  </div>
</div>
