import { Injectable } from '@angular/core'
import { SelectedInstallation } from 'src/app/shared/models/selected-installation.model'

const REDIRECT_ROUTE_KEY = 'redirectroute'
const SELECTED_INSTALLATION = 'selectedinstallation'

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  private readonly storage: Storage = sessionStorage

  public get redirectRoute(): string | null {
    return this.storage.getItem(REDIRECT_ROUTE_KEY) ?? null
  }

  public setRedirectRoute(route: string) {
    sessionStorage.setItem(REDIRECT_ROUTE_KEY, route)
  }

  public removeRedirectRoute() {
    sessionStorage.removeItem(REDIRECT_ROUTE_KEY)
  }

  public setSelectedInstallation(installation: SelectedInstallation) {
    sessionStorage.setItem(SELECTED_INSTALLATION, JSON.stringify(installation))
  }

  public removeSelectedInstallation() {
    sessionStorage.removeItem(SELECTED_INSTALLATION)
  }

  public get customerNumber(): string | null {
    const installation = sessionStorage.getItem(SELECTED_INSTALLATION)
    return JSON.parse(installation)?.customerNumber ?? null
  }

  public get installationNumber(): string | null {
    const installation = sessionStorage.getItem(SELECTED_INSTALLATION)
    return JSON.parse(installation)?.installationNumber ?? null
  }
}
