import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs'
import { isAssetUrl } from './interceptor.utils'

/**
 * A generic HttpInterceptor that avoids intercepting requests targeting assets
 * and other urls specified by the canIntercept function
 */
export abstract class ScopedHttpInterceptor implements HttpInterceptor {

  canIntercept(url: string): boolean {
    // Intended to: return true;
    // But this avoids the unused parameter linter error
    return url === url
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return (
      this.isInterceptable(req.url)
        ? this.onIntercept(req, next)
        : next.handle(req)
    )
  }

  private isInterceptable(url: string): boolean {
    // if it's not an asset URL and the programmer decided to intercept it
    return isAssetUrl(url) === false && this.canIntercept(url)
  }
  abstract onIntercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>;
}
