/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReadingType } from './readingType';
import { MonthlyReadingLine } from './monthlyReadingLine';
import { ReadingAddress } from './readingAddress';
import { PreviousReading } from './previousReading';


export interface MonthlyReadingCard { 
    id: string;
    readingDate: string;
    readingData: string;
    readingCardNumber: string;
    type: ReadingType;
    readingAddress: ReadingAddress;
    readingLines: Array<MonthlyReadingLine>;
    previousReading?: PreviousReading;
}

