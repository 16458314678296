import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { BASE_PATH } from '../services/backend'
import { AuthService } from './auth.service'
import { safelyParseUrl } from './interceptor.utils'
import { ScopedHttpInterceptor } from './scoped-http-interceptor'

@Injectable()
export class AuthInterceptor extends ScopedHttpInterceptor {
  constructor(
    @Inject(BASE_PATH) private apiBasePath: string,
    private authService: AuthService) {
    super()
  }

  override canIntercept(url: string): boolean {
    const hasToken = this.authService.getAccessToken !== null
    return (
      hasToken &&
      this.isApiEndpoint(url)
    )
  }

  override onIntercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const cloned = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${this.authService.getAccessToken}`),
      withCredentials: true,
    })
    return next.handle(cloned)
  }

  private isApiEndpoint(requestUrl: string): boolean {
    const urlObj = safelyParseUrl(requestUrl)
    return (
      urlObj.href.startsWith(this.apiBasePath) && // Check if it's *-api-mit.evida.dk
      urlObj.pathname.startsWith('/api') // check if it targets an /api endpoint
    )
  }
}
