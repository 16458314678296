<form [formGroup]="myForm">
  <mat-form-field appearance="outline" class="evida-search">
    <mat-icon svgIcon="evida_mit_evida_search" matPrefix>search</mat-icon>
    <input
      type="text"
      [placeholder]="'search.search_faq' | translate"
      [attr.aria-label]="'search.search_faq' | translate"
      matInput
      [formControl]="searchControl"
      [matAutocomplete]="auto"
      aria-autocomplete="list"
    />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
