<ng-template #content>
  <mat-icon *ngIf="iconPrefixId && !iconPrefixId.startsWith('evida_')" class="evida-button-prefix-icon">
    {{ iconPrefixId }}
  </mat-icon>
  <mat-icon
    class="evida-button-prefix-icon"
    *ngIf="iconPrefixId && iconPrefixId.startsWith('evida_')"
    [svgIcon]="iconPrefixId"
  >
  </mat-icon>
  <ng-content></ng-content>
  <mat-icon class="evida-button-suffix-icon" *ngIf="iconSuffixId && !iconSuffixId.startsWith('evida_')">{{
    iconSuffixId
  }}</mat-icon>
  <mat-icon
    class="evida-button-suffix-icon"
    *ngIf="iconSuffixId && iconSuffixId.startsWith('evida_')"
    [svgIcon]="iconSuffixId"
  ></mat-icon>
</ng-template>

<div class="evida-button px-0 w-full sm:w-auto">
  <ng-container [ngSwitch]="type">
    <button [tabindex]=[tabindex]
      mat-raised-button
      *ngSwitchCase="'back'"
      class="evida-button-back-button h-50px w-full"
      [disabled]="disabled"
      (click)="clicked.emit(null)"
      type="button"
    >
      <mat-icon color="primary" class="evida-button-circle-icon left">chevron_left</mat-icon>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>

    <button [tabindex]=[tabindex]
      mat-raised-button
      *ngSwitchCase="'forward'"
      class="evida-button-forward-button h-50px w-full"
      [disabled]="disabled"
      (click)="clicked.emit(null)"
      type="button"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
      <mat-icon matSuffix color="primary" class="evida-button-circle-icon right"> chevron_right </mat-icon>
    </button>

    <button [tabindex]=[tabindex]
      mat-raised-button
      *ngSwitchCase="'round'"
      class="evida-button-round-button h-50px w-full"
      [disabled]="disabled"
      (click)="clicked.emit(null)"
      type="button"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>

    <button [tabindex]=[tabindex]
      mat-stroked-button
      color="primary"
      *ngSwitchCase="'primary'"
      [disabled]="disabled"
      (click)="clicked.emit(null)"
      class="px-20px mr-10px h-50px w-full"
      type="button"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
    <button [tabindex]=[tabindex]
      mat-stroked-button
      color="secondary"
      *ngSwitchCase="'secondary'"
      [disabled]="disabled"
      (click)="clicked.emit(null)"
      class="px-20px mr-10px h-50px w-full"
      type="button"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
    <button [tabindex]=[tabindex]
      mat-stroked-button
      class="fg-darkOrange h-50px w-full"
      *ngSwitchCase="'secondary_orange'"
      [disabled]="disabled"
      (click)="clicked.emit(null)"
      type="button"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>

    <button [tabindex]=[tabindex]
      mat-raised-button
      color="tertiary"
      *ngSwitchCase="'tertiary'"
      class="px-20px mr-10px w-full tertiary-button"
      [disabled]="disabled"
      (click)="clicked.emit(null)"
      type="button"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>

    <button [tabindex]=[tabindex]
      mat-flat-button
      color="quaternary"
      *ngSwitchCase="'quaternary'"
      class="bg-petroleum5pct w-full"
      [disabled]="disabled"
      (click)="clicked.emit(null)"
      type="button"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>

    <!-- Button made specifically for the toolbar -->
    <button [tabindex]=[tabindex]
      mat-stroked-button
      color="secondary"
      *ngSwitchCase="'toolbar'"
      [disabled]="disabled"
      (click)="clicked.emit(null)"
      class="px-20px mr-10px h-40px md:h-50px w-full"
      type="button"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>

    <mat-icon 
      *ngSwitchCase="'info'"
      [tabindex]=[tabindex] 
      class="cursor-pointer ml-10px"
      svgIcon="evida_state_information"
      inline="true" 
      (click)="clicked.emit()">
    </mat-icon>

    <span *ngSwitchDefault style="color: red">
      <button [tabindex]=[tabindex]
        title="Unhandled enum value for {{ type.toString() }}"
        mat-flat-button
        color="primary"
        *ngSwitchCase="'quaternary'"
        class="bg-petroleum5pct error w-full"
        [disabled]="disabled"
        (click)="clicked.emit(null)"
        type="button"
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </button>
    </span>
  </ng-container>
</div>
