/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { Contract } from './contract';


export interface ProfileInstallation { 
    installationNumber: string;
    meterNumber?: string | null;
    address: Address;
    /**
     * Display friendly reading plan  da: Aflæsningsplan til visning
     */
    displayReadingPlan: string;
    contract: Contract;
    meterUnits?: string | null;
}

