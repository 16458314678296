/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReadingLineReceipt } from './readingLineReceipt';
import { AddressResponse } from './addressResponse';


/**
 * Represents the full information for a receipt after performing an annual reading
 */
export interface ReadingReceipt { 
    accountNumber?: string | null;
    readingCardNumber?: string | null;
    readingPeriodStart?: string;
    readingPeriodEnd?: string;
    signDateTime?: string;
    address?: AddressResponse;
    /**
     * All registered lines, in the order they must be displayed
     */
    readingLines?: Array<ReadingLineReceipt> | null;
}

