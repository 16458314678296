import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { FlowMessages } from 'src/app/soft-login/base-reading'
import { filter, map, tap } from 'rxjs/operators'

import { BreakpointObserver } from '@angular/cdk/layout'
import { Breakpoints } from 'src/app/utils/breakpoints'
import { environment } from 'src/environments/environment'
import { Features } from '../../models/features.model'
import { GasReading } from '../../models/gas-reading.model'
import { Observable } from 'rxjs'
import Player from '@vimeo/player'
import { fromEvent } from 'rxjs'

@Component({
  selector: 'app-how-to',
  templateUrl: './how-to.component.html',
  styleUrls: ['./how-to.component.scss'],
})
export class HowToComponent implements AfterContentInit, AfterViewInit, OnInit {
  @Input() public title: string
  @Input() public descripton: string
  @Input() public infoMessage: string
  @Input() public videoOverlayText: string
  @Input() public videoSideText: string
  @Input() public messages: FlowMessages
  @Input() public gasReading: GasReading
  @Input() public hasMoreReadingCards: boolean
  @Output() public continue = new EventEmitter()
  @Output() public back = new EventEmitter()
  @Output() public letUsKnowClick = new EventEmitter()
  @Output() public videoReady = new EventEmitter()
  @Output() public videoFailed = new EventEmitter()

  @ViewChild('video')
  public video: ElementRef<HTMLIFrameElement>
  public videoPlayer: Player

  public isXS$: Observable<boolean>

  public videoWidth: number
  public videoHeight: number
  public hasMoreReading$: Observable<boolean>
  public iframeHasErrors$: Observable<boolean | undefined>

  private readonly designVideoAspectRatio = 328 / 585

  constructor(public features: Features, private breakpointObserver: BreakpointObserver) { }

  public ngOnInit() {
    this.iframeHasErrors$ = fromEvent(window, 'message').pipe(
      filter((message: MessageEvent) => message.origin.includes('.vimeo.com')),
      filter((message: MessageEvent) => message.data.event === 'error'),
      tap((_message: MessageEvent) => this.videoFailed.emit()),
      map((_message: MessageEvent) => true)
    )
    this.isXS$ = this.breakpointObserver.observe(Breakpoints.xs).pipe(
      map((value) => value.matches)
    )
  }
  public ngAfterViewInit() {
    this.videoPlayer = new Player(this.video.nativeElement)
    this.videoPlayer.on('loaded', () => {
      this.videoReady.emit()
    })
  }

  public ngAfterContentInit(): void {
    setTimeout(() => this.setVideoWidthEqualToParent(), 250)
  }

  public onResize(_event): void {
    this.setVideoWidthEqualToParent()
  }

  public goToWrongReceiver(): void {
    this.videoPlayer.pause()
    this.letUsKnowClick.emit()
  }

  public backClicked() {
    this.videoPlayer.pause()
    this.back.emit()
  }

  public continueClicked() {
    this.videoPlayer.pause()
    this.continue.emit()
  }

  public shouldShowWrongReceiverFlow() {
    return environment.features.enableWrongReceiverFlow
  }

  private setVideoWidthEqualToParent(): void {
    const videoParent = document.querySelector<HTMLElement>('#video-container')
    this.videoWidth = videoParent.offsetWidth
    this.videoHeight = this.videoWidth * this.designVideoAspectRatio
  }
}
