import { Component } from '@angular/core'
import { Location } from '@angular/common'

@Component({
  selector: 'app-user-blocked-error',
  templateUrl: './user-blocked-error.component.html',
  styleUrls: ['./user-blocked-error.component.scss']
})
export class UserBlockedErrorComponent {

  constructor(private location: Location){}

  retry() {
    this.location.back()
  }
}
