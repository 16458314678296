import { Component } from '@angular/core'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import { AuthService } from 'src/app/shared/auth/auth.service'
import { UserSessionIdService } from 'src/app/user-session/services/user-session-id.service'
import { RedirectTo } from 'src/app/utils/redirect-to'
import { Utils } from 'src/app/utils/utils'

@Component({
  selector: 'app-user-session',
  templateUrl: './user-session.component.html',
  styleUrls: ['./user-session.component.css']
})
export class UserSessionComponent {

  constructor(
    private snack: MatSnackBar,
    private authService: AuthService
  ) { }

  public get userSessionId(): string {
    return UserSessionIdService.getSessionId()
  }

  public onCopyUserSessionId() {
    if (this.userSessionId) {
      Utils.copyToClipboard(this.userSessionId)
      this.snack.open('Kopieret til udklipsholderen!', /*action*/ undefined, {
        duration: 1200,
      })
    }
  }

  public onResetUserSessionId() {
    UserSessionIdService.resetSessionId()
    this.snack.open('Sessions ID nulstillet', /*action*/ undefined, {
      duration: 1200,
    })
  }

  public onBack() {
    RedirectTo.loginOrWelcomeLandingPage(this.authService.isAuthenticated)
  }
}
