import { Component, EventEmitter, Input, Output } from '@angular/core'


@Component({
  selector: 'app-simple-message',
  templateUrl: './simple-message.component.html',
  styleUrls: ['./simple-message.component.scss'],
})
export class SimpleMessageComponent {
  @Input() public title: string
  @Input() public description: string
  @Input() public buttonText: string
  @Input() public showButton = true
  @Output() public clicked = new EventEmitter()
  constructor() {}
}
