import { Component, Input, forwardRef } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

export const EVIDA_SWITCH_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EvidaSwitchComponent),
  multi: true,
}

@Component({
  selector: 'app-evida-switch',
  templateUrl: './evida-switch.component.html',
  styleUrls: ['./evida-switch.component.scss'],
  providers: [EVIDA_SWITCH_CONTROL_VALUE_ACCESSOR],
})
export class EvidaSwitchComponent implements ControlValueAccessor {
  @Input() public enabled = true

  // variables that will hold the callbacks passed from ControlValueAccessor interface
  private onTouchedCallback: () => void = EvidaSwitchComponent.doNothing
  private onChangeCallback: (_: any) => void = EvidaSwitchComponent.doNothing

  private internalValue: boolean
  public get value(): boolean {
    return this.internalValue
  }
  public set value(v: boolean) {
    if (v !== this.internalValue) {
      this.internalValue = v
      this.onChangeCallback(v)
    }
  }

  private static readonly doNothing = () => { }


  public onBlur(): void {
    this.onTouchedCallback()
  }

  // From ControlValueAccessor interface
  public writeValue(value: any): void {
    if (value !== this.internalValue) {
      this.internalValue = value
    }
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn
  }
}
