/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MeterConsumption } from './meterConsumption';


export interface CustomerInstallationConsumptionsResponse { 
    consumptions: Array<MeterConsumption>;
    hasMoreReadings: boolean;
    /**
     * A continuation token which should be provided in the next request in order to get the next {count} items from the API.  This token will only be null if it is not possible to retrieve more readings. (HasMoreReadings == true)
     */
    continuationToken?: string | null;
}

