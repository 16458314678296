/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TerminationReadingCardModel } from './terminationReadingCardModel';
import { ReadingType } from './readingType';
import { CardType } from './cardType';
import { OverviewReadingCardLine } from './overviewReadingCardLine';
import { ReadingAddress } from './readingAddress';


export interface OverviewReadingCard { 
    readingDate: string;
    readingCardNumber: string;
    type: ReadingType;
    meterNumber: string;
    address: ReadingAddress;
    readingLines: Array<OverviewReadingCardLine>;
    terminationReadingCardInformation?: TerminationReadingCardModel;
    cardType: CardType;
}

