/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GrantEligibilityDeminimisRequest } from './grantEligibilityDeminimisRequest';
import { HeatingSource } from './heatingSource';


export interface GrantEligibilityRequest { 
    newHeatingSource: HeatingSource;
    newHeatingDate: string;
    contactEmail: string;
    contactPhone: string;
    ownershipVerified: boolean;
    grantDeminimis?: GrantEligibilityDeminimisRequest;
}

