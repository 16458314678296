import { Injectable } from '@angular/core'

export class LoggerConfig {
  // Enables or disables the logger
  enabled: boolean
  // Enables or disables error logs
  errorLogs: boolean
  // Enables or disables debug logs
  debugLogs: boolean
  // Enables or disables remote logging service
  remoteReporting: boolean
}

@Injectable({ providedIn: 'root' })
export class Logger {
  constructor(private loggerConfig: LoggerConfig) {}

  public debug(...messages: any[]) {
    const { enabled, debugLogs } = this.loggerConfig
    if (enabled && debugLogs) {
      console.log(...messages)
    }
  }

  public info(...messages: any[]) {
    const enabled = this.loggerConfig.enabled
    if (!enabled) {
      return
    }
    // eslint-disable-next-line no-console
    console.info(...messages)
  }

  public error(...messages: any[]) {
    const { enabled, errorLogs } = this.loggerConfig
    if (!enabled || !errorLogs) {
      return
    }
    console.error(...messages)
  }

  public warn(...messages: any[]) {
    const enabled = this.loggerConfig.enabled
    if (!enabled) {
      return
    }
    console.warn(...messages)
  }

  public alwaysLog(...messages: any[]) {
    // eslint-disable-next-line no-console
    console.info(...messages)
  }

  public alwaysLogError(...messages: any[]) {
    console.error(...messages)
  }
}
