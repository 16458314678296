import { StepChange } from './../../models/evida-stepper-controller.model'
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core'
import { EvidaStepperController } from '../../models/evida-stepper-controller.model'

@Component({
  selector: 'app-evida-stepper',
  templateUrl: './evida-stepper.component.html',
  styleUrls: ['./evida-stepper.component.scss']
})
export class EvidaStepperComponent implements OnInit {

  @Input() public controller: EvidaStepperController
  @Output() public stepChanged = new EventEmitter<StepChange>()

  constructor() { }

  public ngOnInit(): void {
    if (this.controller != null) {
      this.controller.stepChangeListener = this.onStepChange.bind(this)
    }
  }
  private onStepChange($change: StepChange): void {
    this.stepChanged.emit($change)
  }

}
