
import { Injectable } from '@angular/core'
import { ReadingRoutes } from '../soft-login/models/reading-routes.model'
import { Logger } from '../utils/logger'
import { ScreenNameProvider } from './screen-name-provider'

/**
 * Add the different screen names that we'd like to track here.
 */
export const EvidaScreens = {
  Login: {
    Login: 'Login',
    Business: {
      ForgotPassword: 'Erhverv-MitID-GlemtAdgangskoden',
      TieRelationship: 'Erhverv-MitID-KoblMitIDForsoeg',
      ContactCustomerService: 'Erhverv-MitID-KontaktformularEvida'
    }
  },
  Dashboard: 'dashboard',
  Decoupling: {
    // url: /flow/cancellation
    Root: 'Afkobling',
    Installation: 'Afkobling - Installation',
    Grant: 'Afkobling - Accepteret',
    CPR: 'Afkobling - CPR',
    Signature: 'Afkobling - Underskrift',
    Confirmation: 'Afkobling - Kvittering',
  },
  AnnualReading: {
    Dashboard: 'Aflaesning - oversigt',
    HowTo: 'Aflaesning - Saadan Aflaeser Du',
    Reading: 'Aflaesning - Aflaesning',
    Receipt: 'Aflaesning - Kvittering',
    AlreadyRead: 'Aflaesning - Er foretaget',
  },
  MonthlyReading: {
    Dashboard: 'Månedsaflæsning - oversigt',
    Reading: 'Månedsaflæsning - Aflæsning',
    Receipt: 'Månedsaflæsning - Kvittering',
    AlreadyRead: 'Månedsaflæsning - Er foretaget',
  },
  WrongReceiver: {
    Root: 'Forkert Modtager',
    Moved: 'Forkert Modtager - Flytning',
    NoLongerAdmin: 'Forkert Modtager - Ikke admin',
    ReceiverTakenDown: 'Forkert Modtager - Maaler nedtaget',
    OtherHeatingSource: 'Forkert Modtager - Anden opvarmning',
    NeverAConsumer: 'Forkert Modtager - Ikke forbruger',
    GasForCookingRemoved: 'Forkert Modtager - Gaskomfur fjernet',
    Other: 'Forkert Modtager - Andet',
  },
  Error: {
    unexpected: 'Noget gik galt',
    busy: 'Service har travlt',
    timeout: 'Xellent timeout'
  },
}

@Injectable({ providedIn: 'platform' })
export class EvidaScreenNameProvider implements ScreenNameProvider {
  constructor(private logger: Logger) { }

  public getScreenNameFromUrl(url: string): string | null {
    const screenName = this._getScreenNameFromUrl(url)
    if (!screenName) {
      this.logger.warn('EvidaScreenNameProvider: Unhandled url', url)
    }
    return screenName
  }

  // TODO: Provide unit-tests for this switch statement
  private _getScreenNameFromUrl(url: string): string | null {
    const is = (pattern: string): boolean => {
      const regexp = new RegExp(pattern)
      return regexp.test(url)
    }
    switch (true) {
    case is('/login'):
      return EvidaScreens.Login.Login
    case is('/flow/cancellation'):
      return this.getCancellationScreenName(url)
      // Test this one first as it's more specific!
    case is(`/flow/${ReadingRoutes.annualReading}/forkert-modtager`):
      return this.getWrongReceiverScreenName(url)
      // Is any screen of the reading flow
    case is(`/flow/${ReadingRoutes.annualReading}`):
      return this.getReadingScreenName(url)
      // Add more names here
    default:
      return null
    }
  }

  // TODO: Provide unit-tests for this switch statement
  private getCancellationScreenName(url: string): string | null {
    switch (true) {
    case /\/flow\/cancellation\/installation/.test(url):
      return EvidaScreens.Decoupling.Installation
    case /\/flow\/cancellation\/grant/.test(url):
      return EvidaScreens.Decoupling.Grant
    case /\/flow\/cancellation\/cpr/.test(url):
      return EvidaScreens.Decoupling.CPR
    case /\/flow\/cancellation\/signature/.test(url):
      return EvidaScreens.Decoupling.Signature
    case /\/flow\/cancellation\/confirmation/.test(url):
      return EvidaScreens.Decoupling.Confirmation
      // Since this regexp will match against ANY of the steps it should go below them
    case /\/flow\/cancellation/.test(url):
      return EvidaScreens.Decoupling.Root
    default:
      return null
    }
  }

  private getReadingScreenName(url: string): string | null {
    const urlNoParams = url.split('?')[0]
    switch (true) {
    case /^\/flow\/aflaesning$/.test(urlNoParams):
    case /^\/flow\/aflaesning\/$/.test(urlNoParams):
      return EvidaScreens.AnnualReading.Dashboard
    default:
      return null
    }
  }

  /**
   * This function assumes we've already checked for @param url
   * starting with /flow/aflaesning/forkert-modtager
   *
   * @returns
   */
  private getWrongReceiverScreenName(url: string): string | null {
    const urlNoParams = url.split('?')[0]
    const is = (pattern: string): boolean => {
      const regexp = new RegExp(`/flow/${ReadingRoutes.annualReading}/forkert-modtager${pattern}`)
      return regexp.test(url)
    }
    const isRoot = [
      // regexp that tests for exactly /flow/aflaesning/forkert-modtager
      /^\/flow\/aflaesning\/forkert-modtager$/.test(urlNoParams),
      // regexp that tests for exactly /flow/aflaesning/forkert-modtager/
      /^\/flow\/aflaesning\/forkert-modtager\/$/.test(urlNoParams),
      // It's root if some of the predicates tested true
    ].some((isTrue) => isTrue)

    switch (true) {
    case is('/moved'):
      return EvidaScreens.WrongReceiver.Moved
    case is('/noLongerAdmin'):
      return EvidaScreens.WrongReceiver.NoLongerAdmin
    case is('/installationRemoved'):
      return EvidaScreens.WrongReceiver.ReceiverTakenDown
    case is('/changedHeatingMethod'):
      return EvidaScreens.WrongReceiver.OtherHeatingSource
    case is('/neverBeenACustomer'):
      return EvidaScreens.WrongReceiver.NeverAConsumer
    case is('/gasForCookingRemoved'):
      return EvidaScreens.WrongReceiver.GasForCookingRemoved
    case is('/other'):
      return EvidaScreens.WrongReceiver.Other
    case isRoot:
      return EvidaScreens.WrongReceiver.Root
    default:
      return null
    }
  }
}
