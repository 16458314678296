/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReadingLineRequestItem } from './readingLineRequestItem';
import { DiscrepancyReasonEnum } from './discrepancyReasonEnum';


export interface ReadingWithDiscrepancyRequest { 
    cardData: string;
    lineReadings: Array<ReadingLineRequestItem>;
    discrepancyReasons: Array<DiscrepancyReasonEnum>;
    otherReason?: string | null;
    phoneNumber?: string | null;
    email?: string | null;
    /**
     * Ids of images that has been previously uploaded.
     */
    imageIds?: Array<string> | null;
}

