/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReadingCard } from './readingCard';
import { AddressResponse } from './addressResponse';


export interface ReadingsResponse { 
    /**
     * Installation number
     */
    installationNumber: string;
    address: AddressResponse;
    /**
     * Map of reading card number -> reading card data
     */
    cards: { [key: string]: ReadingCard; };
}

