import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'

import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { RedirectTo } from 'src/app/utils/redirect-to'

/**
 * Use this guard on the canActivate or canActivateChild chains on the routing modules.
 *
 * This guard looks into the data property of the ActivatedRoute and verifies the features array is filled with trues.
 *
 * Some sample usage could be:
 *
 * const routes = [
 *  {
 *    path: 'some-path',
 *    component: SomeComponent,
 *    canActivate: [FeatureGuard, MaybeAnotherGuard],
 *    data: {
 *      features: [environment.features.someComponentEnabled]
 *    }
 *  }
 * ];
 *
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureGuard  {
  constructor(private router: Router) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkFlags(route, state)
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkFlags(route, state)
  }

  private checkFlags(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const featureBooleanFlags: Array<boolean> = route.data.features
    const canAccess = featureBooleanFlags.every((flag) => flag === true)
    const redirectTo: string = route.data.redirectTo

    // If unnaccessible redirect to 404
    if (!canAccess) {
      if (redirectTo) {
        this.router.navigate([redirectTo])
      } else {
        RedirectTo.notFound(this.router)
      }
    }
    return canAccess
  }
}
