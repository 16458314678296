/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Invoice } from './invoice';


export interface InvoicesResponse { 
    hasMoreInvoices: boolean;
    /**
     * A token which should be provided in the next request if you wish to continue from the next item. Will be null if MitEvida.BFF.REST.Features.Invoice.API.Models.InvoicesResponse.HasMoreInvoices is null.
     */
    continuationToken?: string | null;
    invoices: Array<Invoice>;
}

