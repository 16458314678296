/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { TerminationInfo } from './terminationInfo';
import { TerminationStatus } from './terminationStatus';


export interface Installation { 
    /**
     * Installation identification number as used internally at Evida
     */
    identifier?: string | null;
    customerName?: string | null;
    address?: Address;
    /**
     * CVR numbers registered on the CVR address.  Private customers will have an empty array.
     */
    cvrNumbers: Array<string>;
    status?: TerminationStatus;
    terminationInfo?: TerminationInfo;
    /**
     * Whether the grant pool has enough funds to pay for the decoupling in the region where the installation is
     */
    poolHasRemainingFunds?: boolean;
}

