<ng-container>
  <app-toolbar></app-toolbar>
  <div class=" max-w-780px mx-auto flex flex-col md:flex-row mt-100px">
    <div class="md:w-2/12 w-full text-center sm:text-left">
      <mat-icon [svgIcon]="'evida_error_screen'" class="evida-maintenance__image"></mat-icon>
    </div>
    <div class="md:w-10/12 md:pl-20px w-full">
        <p [innerHtml]="'maintenance.description' | translate"></p>
    </div>
  </div>
</ng-container>
