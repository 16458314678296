<mat-card class="flex flex-col mt-medium p-medium w-full">
  <div class="evida-error max-w-780px mx-auto flex flex-col w-full md:flex-row">
    <div class="md:w-2/12 w-full text-center sm:text-left">
      <mat-icon [svgIcon]="icon" class="evida-error__image"></mat-icon>
    </div>
    <div class="md:w-10/12 md:pl-20px w-full">
      <div class="evida-error__content">
        <ng-content></ng-content>
        <p *ngIf="errorCode" class="evida-error__content__errorCode">
          Fejlkode: <code class="selectAll">{{ errorCode }}</code>
          <br>
          <br>
        </p>
      </div>
      <div class="flex flex-col" *ngIf="showCorrelationId">
        <ng-container *ngIf="correlationId">
          <span translate="shared.errormessages.correlationId"></span>
          <code>{{ correlationId }}</code>
          <app-evida-button class="mt-medium" type="secondary" (clicked)="onCopyCorrelationId()">
            {{ 'shared.buttons.copyToClipboard' | translate }}
          </app-evida-button>
        </ng-container>
      </div>
      <mat-card-footer *ngIf="showRetryButton || showCancelButton">
        <div class="flex w-full flex-col-reverse sm:flex-row justify-between">
          <div class="mb10">
            <app-evida-button
              type="secondary"
              (clicked)="secondaryButtonClicked()"
              *ngIf="showCancelButton"
              class="evida-error__cancelButton"
            >
              {{ cancelButtonText | translate }}
            </app-evida-button>
          </div>
          <div class="mb10 text-right">
            <app-evida-button
              *ngIf="showRetryButton"
              type="primary"
              (clicked)="primaryButtonClicked()"
              class="evida-error__retrybutton"
            >
              {{ retryButtonText | translate }}
            </app-evida-button>
          </div>
        </div>
      </mat-card-footer>
    </div>
  </div>
</mat-card>
