import { map } from 'rxjs/operators'
import { Component, Input, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Observable, combineLatest } from 'rxjs'
import { GoogleAnalytics } from 'src/app/tracking/google-analytics'
import { EvidaEvents } from 'src/app/tracking/evida-events'


interface InfoLink {
  text: string;
  href: string;
}
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  @Input()
  public showFloatingSection = true
  public telephoneLink$: Observable<InfoLink>
  public infoLinks$: Observable<InfoLink[]>
  constructor(private translate: TranslateService) { }

  public ngOnInit(): void {
    const t = this.translate
    const phoneLinkParts = [
      t.get('shared.company.support_phone.raw'),
      t.get('shared.company.support_phone.friendly')
    ]
    this.telephoneLink$ = combineLatest(phoneLinkParts).pipe(
      map(([raw, friendly]) => ({
        text: friendly,
        href: `tel:${raw}`
      }))
    )
    this.infoLinks$ = this.translate.get('footer.info.links')
  }

  public onLinkClicked(link: InfoLink) {
    GoogleAnalytics.trackLink(EvidaEvents.Footer.clicks.link, link)
  }
}
