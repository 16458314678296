import { environment } from 'src/environments/environment'
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { all as allCookies } from 'browser-cookies'

@Injectable()
export class QueueItInterceptor implements HttpInterceptor {

  constructor() {
    this.updateQueueItCookiesDomain()
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const areValidDomains = this.areValidDomains(request.url)

    if (areValidDomains) {
      // Set withCredentials to ensure queue-it cookies are send to server
      const cloned = request.clone({
        withCredentials: true,
      })
      this.updateQueueItCookiesDomain()
      return next.handle(cloned)
    } else {
      return next.handle(request)
    }
  }

  private updateQueueItCookiesDomain() {
    // Queue-it cookies are set on dev-mit.evida.dk.
    // Ensure the same cookies are available on .evida.dk (dev-api-mit.evida.dk)
    // Queue-it cookies are prefixed with 'QueueITAccepted-'
    const cookies = allCookies()
    for (const key in cookies) {
      if (Object.prototype.hasOwnProperty.call(cookies, key)) {
        const value = cookies[key]
        if (key.startsWith('QueueITAccepted-')) {
          document.cookie = key + '=' + encodeURIComponent(value) + '; domain=' + '.evida.dk' + '; path=/'
        }
      }
    }
  }

  private areValidDomains(requestUrl: string): boolean {
    const validUrlProtocol = ['http://', 'https://'].some(protocol => requestUrl.startsWith(protocol))
    if (validUrlProtocol) {
      const url = new URL(requestUrl)
      return environment.queueIt.interceptDomains.some(hostname => hostname === url.hostname)
    }
    return false
  }
}
