<div class="dialog-container">
  <div class="flex flex-col">
    <h3
      *ngIf="data.title"
      class="mb-20"
      [translate]="data.title"></h3>

    <div>
      <div *ngIf="data.component">
        <ng-container *ngComponentOutlet="data.component"></ng-container>
      </div>
      <p
        *ngIf="data.message"
        [innerHTML]="data.message | translate"></p>
    </div>

    <div
      class="flex flex-col justify-end mt-30px w-auto sm:flex-row" [ngClass]="{ 'justify-between' : data?.justifyBetween }"
      *ngIf="data.primaryButtonText || data.secondaryButtonText">
      <app-evida-button
        *ngIf="data.secondaryButtonText"
        type="secondary"
        (clicked)="secondaryButtonClick()">
        {{ data.secondaryButtonText | translate }}
      </app-evida-button>

      <div
        *ngIf="data.primaryButtonText"
        class="mt-10px ml-0 sm:mt-0 sm:ml-10px"></div>

      <app-evida-button
        *ngIf="data.primaryButtonText"
        type="primary"
        (clicked)="primaryButtonClick()">
        {{ data.primaryButtonText | translate }}
      </app-evida-button>
    </div>
  </div>
</div>
