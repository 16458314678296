import { Component, Input } from '@angular/core'
import { AddressMapper } from 'src/app/soft-login/mappers/AddressMapper'
import { Address, ProfileInstallation } from '../../services/backend'

@Component({
  selector: 'app-installation-cell',
  templateUrl: './installation-cell.component.html',
  styleUrls: ['./installation-cell.component.scss'],
})
export class InstallationCellComponent {
    @Input() public installation: ProfileInstallation
    @Input() public smallSize = false
    @Input() public showMeterInfo = true

    public formatAddress(address: Address): string {
      return AddressMapper.formatAddress(address)
    }

    public get meterInfo(): boolean{
      return !!(this.installation.meterUnits || this.installation.meterNumber)
    }
}
