const getBaseUrl = () => document.querySelector('base')?.getAttribute('href') ?? '/'



export const isAssetUrl = (
  url: string,
  origin: string = window.location.origin,
  baseUrl: string = getBaseUrl()): boolean => {
  const urlObj = safelyParseUrl(url)
  const assetUrlRootPath = (
    [baseUrl, 'assets']
      // Filter out baseUrl in case it's empty
      .join('/')
      .replaceAll('//', '/')
  )
  const assetUrl = new URL(assetUrlRootPath, origin)
  const targetIsSameAsHost = urlObj.hostname === assetUrl.hostname
  const isAnAssetFile = urlObj.pathname.startsWith(assetUrl.pathname)
  return targetIsSameAsHost && isAnAssetFile
}


export const safelyParseUrl = (url: string) => {
  try {
    return new URL(url)
  } catch {
    // Sometimes we get the path of the URL
    // We can workAround this by providing the origin ourselves
    // Which includes the URL schema (http:// or https://)
    // the domain and the port WITHOUT any parts of the path
    return new URL(url, window.location.origin)
  }
}
