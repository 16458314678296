/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExpectedConsumptionMonth } from './expectedConsumptionMonth';


export interface ExpectedConsumptionYearResponse { 
    /**
     * Map of months (integers) to the expected consumption for the given month.  It is guaranteed that keys 1-12 will always be present, but the values can be null if no data has been recorded for the given month.
     */
    expectedConsumptionMonthMap: { [key: string]: ExpectedConsumptionMonth; };
}

