/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Urgency of the reading (normal vs too-late)
 */
export type ReadingUrgency = 'Normal' | 'Asap' | 'Reminder' | 'Fee';

export const ReadingUrgency = {
    Normal: 'Normal' as ReadingUrgency,
    Asap: 'Asap' as ReadingUrgency,
    Reminder: 'Reminder' as ReadingUrgency,
    Fee: 'Fee' as ReadingUrgency
};

