import { Component, OnInit } from '@angular/core'
import { map, startWith } from 'rxjs/operators'

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { Observable } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  public searchControl = new UntypedFormControl()
  public options: string[] = [
    'Bliv kunde',
    'Derfor skal du skifte til gas',
    'Frakobling af din gasledning',
    'Gas i samspil med fjernvarme',
    'Gas til industri',
    'Gas til transport',
    'Grøn gas',
    'Grøn gas Lolland-Falster',
    'Grøn gas til livet',
    'Lugter du gas?',
    'Meld flytning',
    'Nyt biogasanlæg sender grøn gas til forbrugere i Sønderjylland',
    'OL Bioenergy bygger ud og sender mere grøn gas i det østjyske gasnet',
    'Ren besked om gas og sikkerhed',
    'Sikker med gas',
    'Så let får du gas installeret',
    'Sådan skifter du til gas',
    'Tænk grøn gas sammen med fjernvarme',
  ]
  public filteredOptions: Observable<string[]>
  public myForm = new UntypedFormGroup({
    search: this.searchControl
  })

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.filteredOptions = this.searchControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    )
  }

  private _filter(value: string): string[] {
    const filterValue = value.trim().toLowerCase()
    if (filterValue === '') {
      return []
    }

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    )
  }
}
