import { ActivatedRoute } from '@angular/router'
import { Observable, combineLatest } from 'rxjs'
import { filter, map, shareReplay, switchMap } from 'rxjs/operators'
import { ReadingService, ShortLinkResponse } from '../shared/services/backend'


import { Injectable } from '@angular/core'
import { ReadingCustomerDetails } from '../soft-login/base-reading'
import { RxJS } from '../utils/rxjs-utils'
import { Logger } from './../utils/logger'

export type ShortLinkData = ReadingCustomerDetails & {
  redirectToUnknownReceiver: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ShortLinkService {
  private static readonly shortLinkDataKey = 'shortLinkData'
  constructor(private logger: Logger, private reading: ReadingService) { }

  public getCustomerDetailsFromShortLinkInfo(actRoute: ActivatedRoute): Observable<ShortLinkData> {
    const customerDetails$ = actRoute.paramMap.pipe(
      RxJS.logAndRethrow(this.logger),
      map((paramMap) => decodeURIComponent(paramMap.get(ShortLinkService.shortLinkDataKey))),
      switchMap((dataInUrl) => this.reading.apiReadingShortLinkGet(dataInUrl)),
      map<ShortLinkResponse, ReadingCustomerDetails>((response) => {
        const { accountNumber, readingCardNumber } = response
        return accountNumber && readingCardNumber ? { accountNumber, readingCardNumber } : null
      }),
      filter((customerDetails) => customerDetails != null),
      shareReplay(1)
    )
    return combineLatest([actRoute.fragment, customerDetails$]).pipe(
      map(([fragment, customerDetails]) => {
        const safeFragment = fragment ?? ''
        const redirectToUnknownReceiver = safeFragment.includes('flow=unknowntarget')
        return { ...customerDetails, redirectToUnknownReceiver }
      }),
      shareReplay(1)
    )
  }
}
