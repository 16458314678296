import { BreakpointObserver } from '@angular/cdk/layout'
import { AfterContentChecked, Component, HostListener, Input } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { EvidaEvents } from 'src/app/tracking/evida-events'
import { TrackEvent } from 'src/app/tracking/google-analytics'
import { Breakpoints } from 'src/app/utils/breakpoints'
import { RedirectTo } from 'src/app/utils/redirect-to'
import { AuthService } from '../../auth/auth.service'

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements AfterContentChecked {
  public static readonly ELEMENT_ID = 'routerOutlet'

  public contentWidth$: Observable<number>
  public isLg$: Observable<boolean>
  public isMd$: Observable<boolean>

  @Input() public isLoginFlow = true

  @HostListener('window:resize')
  onResize() {
    const marginOffset = 35
    const logoOffset = 335
    const offsetWidth = document.getElementById(
      ToolbarComponent.ELEMENT_ID)?.offsetWidth + marginOffset
    this.contentWidth$ = this.isMd$.pipe(
      map(isMd => {
        const mdOffset = (offsetWidth - logoOffset)
        const lgOfsett = !this.isLoginFlow ? mdOffset : offsetWidth
        return isMd ? mdOffset : lgOfsett
      })
    )
  }

  constructor(
    private bpo: BreakpointObserver,
    private authService: AuthService,
  ) {
    this.isLg$ = this.bpo.observe(Breakpoints.lg).pipe(
      map(it => it.matches)
    )
    this.isMd$ = this.bpo.observe(Breakpoints.md_only).pipe(
      map(it => it.matches)
    )
  }

  public ngAfterContentChecked() {
    this.onResize()
  }

  @TrackEvent(EvidaEvents.Toolbar.clicks.logo)
  public onLogoClick() {
    RedirectTo.loginOrWelcomeLandingPage(this.authService.isAuthenticated)
  }
}
