import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router'
import { Observable, combineLatest } from 'rxjs'
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators'
import { GasReading } from '../shared/models/gas-reading.model'
import { Errors } from '../utils/errors'
import { ExtendedMonthlyReading } from './models/extended-monthly-reading.model'

export interface ReadingCustomerDetails {
  accountNumber: string;
  readingCardNumber: string;
  cardType?: string
}

export interface FlowMessages {
  pre?: string;
  post?: string;
}

/**
 * This is an aggregate of all the parameters necessary to
 * successfully start an annual reading flow.
 *
 * @property accountNumber is the account identifier for the customer
 * @property readingCardNumber is the card identificier for the customer
 * @property gasMeterNumber is the gas-meter we want to take a reading of.
 * @property selectedCardNumber is the card number of the gas-meter we want to take a reading of
 */
export type ReadingFlowInput = ReadingCustomerDetails & {
  gasMeterNumber: string;
  selectedCardNumber: string;
};

export class BaseReading {
  public static readonly ACCOUNT_NUMBER_ROUTING_KEY = 'a'
  public static readonly READING_NUMBER_ROUTING_KEY = 'c'
  public static readonly CARD_TYPE_ROUTING_KEY = 't'
  public static readonly IS_SHORTLINK_IN_KEY = 's'
  public static readonly REDIRECT_WRONG_RECEIVER_ROUTING_KEY = 'wrong'
  public static readonly GASMETER_NUMBER_ROUTING_KEY = 'meterNumber'
  public static readonly SELECTED_CARD_NUMBER_ROUTING_KEY = 'selectedCardNumber'

  public static getGasMeterNumber(route: ActivatedRoute): Observable<string> {
    return route.paramMap.pipe(
      map((paramMap) => decodeURIComponent(paramMap.get(BaseReading.GASMETER_NUMBER_ROUTING_KEY)))
    )
  }

  public static getSelectedCardNumber(route: ActivatedRoute): Observable<string> {
    return route.paramMap.pipe(
      map((paramMap) => decodeURIComponent(paramMap.get(BaseReading.SELECTED_CARD_NUMBER_ROUTING_KEY)))
    )
  }

  public static getCustomerDetails(route: ActivatedRoute): Observable<ReadingCustomerDetails> {
    return route.queryParamMap.pipe(
      map((queryParamMap) => {
        const accNum = decodeURIComponent(queryParamMap.get(BaseReading.ACCOUNT_NUMBER_ROUTING_KEY))
        const cardNum = decodeURIComponent(queryParamMap.get(BaseReading.READING_NUMBER_ROUTING_KEY))
        if (accNum && cardNum) {
          return { accountNumber: accNum, readingCardNumber: cardNum }
        } else {
          Errors.illegalArgument('Some required arguments were not provided')
        }
      })
    )
  }

  public static hasMoreReadingCards(
    gasReading$: Observable<Array<GasReading | ExtendedMonthlyReading>>
  ): Observable<boolean> {
    return gasReading$.pipe(map((gasReadings) => gasReadings.length > 1))
  }

  public static hasOnlyOneReadingLine(
    gasReading$: Observable<Array<GasReading | ExtendedMonthlyReading>>
  ): Observable<boolean> {
    return gasReading$.pipe(map((gasReadings) => gasReadings.length === 1))
  }

  public static getCustomerDetailsFromSnapshot(route: ActivatedRouteSnapshot): ReadingCustomerDetails {
    const queryParamMap = route.queryParamMap
    const accNum = decodeURIComponent(queryParamMap.get(BaseReading.ACCOUNT_NUMBER_ROUTING_KEY))
    const cardNum = decodeURIComponent(queryParamMap.get(BaseReading.READING_NUMBER_ROUTING_KEY))
    if (accNum && cardNum) {
      return { accountNumber: accNum, readingCardNumber: cardNum }
    } else {
      Errors.illegalArgument('Some required arguments were not provided')
    }
  }

  public static getReadingInput(route: ActivatedRoute): Observable<ReadingFlowInput> {
    const customerDetails$ = BaseReading.getCustomerDetails(route)
    const gasMeterNumber$ = BaseReading.getGasMeterNumber(route)
    const selectedCardNumber$ = BaseReading.getSelectedCardNumber(route)
    return combineLatest([customerDetails$, gasMeterNumber$, selectedCardNumber$]).pipe(
      map((combination) => {
        const [customerDetails, gasMeterNumber, selectedCardNumber] = combination
        return {
          ...customerDetails,
          gasMeterNumber,
          selectedCardNumber
        }
      }),
      distinctUntilChanged((prev, curr) => {
        const sameAccNo = prev.accountNumber === curr.accountNumber
        const sameRedNo = prev.readingCardNumber === curr.readingCardNumber
        const sameGasNo = prev.gasMeterNumber === curr.gasMeterNumber
        const sameCardNo = prev.selectedCardNumber === curr.selectedCardNumber
        return sameAccNo && sameRedNo && sameGasNo && sameCardNo
      }),
      shareReplay(1)
    )
  }
}
