/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Address { 
    street?: string | null;
    city?: string | null;
    zipcode?: string | null;
    houseNumber?: string | null;
    houseLetter?: string | null;
    floorNumber?: string | null;
    apartment?: string | null;
}

