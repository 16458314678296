import { Component, Input } from '@angular/core'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import { Router } from '@angular/router'
import { EvidaEvents } from 'src/app/tracking/evida-events'
import { EvidaScreens } from 'src/app/tracking/evida-screens'
import { GoogleAnalytics, TrackEvent } from 'src/app/tracking/google-analytics'
import { Logger } from 'src/app/utils/logger'
import { RedirectTo } from 'src/app/utils/redirect-to'
import { Utils } from 'src/app/utils/utils'
import { environment } from 'src/environments/environment'
import { ReponseErrors } from '../../../soft-login/models/error.model'
import { LoadingStateService } from '../../services/loading-state.service'

@Component({
  selector: 'app-site-error',
  templateUrl: './site-error-component.html',
  styleUrls: ['./site-error-component.scss'],
})
export class SiteErrorComponent {
  @Input() public errorCode?: string
  @Input() public errorInfo?: string
  @Input() public correlationId?: string
  @Input() public url?: string // The URL where the error occured

  public readonly evidaLoginLink = environment.links.login
  public reponseErrors = ReponseErrors

  constructor(
    private router: Router,
    private logger: Logger,
    private loading: LoadingStateService,
    private snack: MatSnackBar
  ) {
    this.trackErrorUnexpected()
    const state = this.router.getCurrentNavigation()?.extras?.state
    this.logger.error(`Error occured. Navigation state: ${JSON.stringify(state, null, 2)}`)
    if (state) {
      this.errorCode = this.errorCode ? this.errorCode : state.errorCode
      this.errorInfo = this.errorInfo ? this.errorInfo : state.errorInfo
      this.correlationId = this.correlationId ? this.correlationId : state.correlationId
      this.url = this.url ? this.url : state.url
    }
    this.loading.setNotLoading()
  }

  @TrackEvent(EvidaEvents.SiteError.clicks.backToHomepage)
  public backToHomepage() {
    RedirectTo.loginLandingPage()
  }
  // TODO: Should we track this to GA?
  public previousPage() {
    window.history.back()
  }

  public retry() {
    if (this.url) {
      document.location.href = this.url
    }
  }

  public onCopyCorrelationId() {
    if (this.correlationId) {
      Utils.copyToClipboard(this.correlationId)
      this.snack.open('Kopieret til udklipsholderen!', /*action*/ undefined, {
        duration: 1200,
      })
    }
  }

  private trackErrorUnexpected() {
    GoogleAnalytics.trackScreen(EvidaScreens.Error.unexpected)
  }
}
