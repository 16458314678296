<div class="mx-medium mt-30px flex flex-col h-34px">
  <div class="flex flex-row">
    <div role="progressbar" class="w-full h-3px rounded bg-petroleum10pct mr-3px"
      *ngFor="let item of controller.steps; let num = index"
      [ngClass]="{ 'bg-green': controller.currentStepIndex === num }"></div>
  </div>

  <div class="flex flex-row mt-10px">
    <span role="label" *ngFor="let item of controller.steps; let num = index"
      class="w-full h-3px body-small text-petroleum70pct" [ngClass]="{
        'text-darkGreen body-small-header visible': num === controller.currentStepIndex,
        'md:block': num !== controller.currentStepIndex
      }">
      {{ num + 1 }}. {{ item | translate }}
    </span>
  </div>
</div>
