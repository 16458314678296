/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GrantEligibilityDeminimisRequest { 
    /**
     * This field must be specified if the user has CVR numbers
     */
    readAndUnderstoodDeminimisGuide?: boolean | null;
    /**
     * This field must be specified if the user has CVR numbers
     */
    hasPreviouslyReceivedDeminimis?: boolean | null;
    dateOfDeminimisGrant?: string | null;
    amount?: number;
    scheme?: string | null;
    administeredBy?: string | null;
}

