/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TerminationStatus = 'TerminationPreviouslyOrdered' | 'TerminationOrderedBySelf' | 'TerminationOrderedByBackofficeSigned' | 'TerminationOrderedByBackofficeUnsigned' | 'NotTerminated';

export const TerminationStatus = {
    TerminationPreviouslyOrdered: 'TerminationPreviouslyOrdered' as TerminationStatus,
    TerminationOrderedBySelf: 'TerminationOrderedBySelf' as TerminationStatus,
    TerminationOrderedByBackofficeSigned: 'TerminationOrderedByBackofficeSigned' as TerminationStatus,
    TerminationOrderedByBackofficeUnsigned: 'TerminationOrderedByBackofficeUnsigned' as TerminationStatus,
    NotTerminated: 'NotTerminated' as TerminationStatus
};

