<app-evida-error [showRetryButton]="false">
    <div>
        <h2 translate="loginFlow.errorLogin.header"></h2>
        <p translate="loginFlow.errorLogin.body"></p>
        <div class="mb10">
            <app-evida-button type="primary" (clicked)="loginPage()">{{ 'shared.buttons.retry' | translate }}
            </app-evida-button>
        </div>
    </div>
</app-evida-error>
