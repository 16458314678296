import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AnalyticsService } from './api/analytics.service';
import { AnnualReadingService } from './api/annualReading.service';
import { BusinessProfileService } from './api/businessProfile.service';
import { ConsentService } from './api/consent.service';
import { DevelopmentService } from './api/development.service';
import { ExpectedConsumptionService } from './api/expectedConsumption.service';
import { HealthService } from './api/health.service';
import { InstallationsService } from './api/installations.service';
import { InvoiceService } from './api/invoice.service';
import { MonthlyReadingService } from './api/monthlyReading.service';
import { PrivateProfileService } from './api/privateProfile.service';
import { ProductionConsumptionService } from './api/productionConsumption.service';
import { ReadingService } from './api/reading.service';
import { RegisteredConsumptionService } from './api/registeredConsumption.service';
import { TerminationReadingService } from './api/terminationReading.service';
import { TerminationsService } from './api/terminations.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
