<div *ngIf="showFloatingSection" class="contents floating-contac contact-info px-medium md:flex">
  <div class="flex-row contact-info-left-side mr70 xs:mb-10px flex">
    <div class="contact-info-gray-circle">
      <img src="../../../../assets/image/contact-image.png" alt=""/>
    </div>
  </div>
  <div class="contact-info-right-side flex flex-col">
    <p class="fg-black pb20 body-copy mb0">
      <span>Kontakt</span>
    </p>
    <h2 class="fg-black header-medium mb0">
      <span> Har du spørgsmål til os?</span>
    </h2>
    <h2 class="fg-green header-medium mt0">
      <span [innerHTML]="'footer.support_text' | translate"></span>
      <a *ngIf="telephoneLink$ | async as link" class="fg-green nobr" (click)="onLinkClicked(link)" [href]="link.href"
        target="_blank">
        {{ link.text }}
      </a>
    </h2>
  </div>
</div>

<div class="footer p-medium bg-petroleum">
  <div class="flex-row flex-wrap flex">
    <div class="ph0 flex flex-col w-full md:w-1/2">
      <div class="footer__logo">
        <mat-icon svgIcon="evida_negativ" inline="true" aria-label="Evida logo"></mat-icon>
      </div>
    </div>
    <div class="footer__right-side mt--responsive ph0 flex flex-row w-full md:w-1/2">
      <h2 class="footer__info fg-green header-medium mb-0">
        {{ 'footer.info.header' | translate }}
      </h2>
    </div>
  </div>

  <div class="mt50">
    <div class="flex flex-row flex-wrap">
      <div class="ph0 flex flex-col w-full md:w-1/2">
        <p class="body-small fg-white">
          <strong class="fg-white">
            {{ 'shared.company.name' | translate }}
          </strong>
          <br>
          {{ 'shared.company.address' | translate }}
          <br>
          {{ 'shared.company.zip' | translate }} {{ 'shared.company.city' | translate }}
        </p>
      </div>

      <div class="ph0 flex flex-col w-full md:w-1/2">
        <div class="mh0 flex flex-row justify-start lg:justify-end">
          <div class="ph0 flex flex-col w-auto">
            <mat-nav-list dense class="information-label pt-0">
              <ul class="ul--blank" *ngIf="infoLinks$ | async; let infoLinks">
                <li *ngFor="let link of infoLinks">
                  <a class="body-small fg-white" mat-menu-item [href]="link.href" target="_blank"
                    (click)="onLinkClicked(link)">
                    {{ link.text }}
                  </a>
                </li>
              </ul>
            </mat-nav-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
