import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client'
import { UserSessionIdService } from 'src/app/user-session/services/user-session-id.service'
import { environment } from 'src/environments/environment'
import { ConfigId } from '../models/config-id.model'

export class Configuration {

  public static get privateConfig(): OpenIdConfiguration {
    return {
      ...Configuration.baseConfig,
      configId: ConfigId.private,
      clientId: environment.login.privateClientId
    }
  }

  public static get businessConfig(): OpenIdConfiguration {
    return {
      ...Configuration.baseConfig,
      configId: ConfigId.business,
      clientId: environment.login.businessClientId
    }
  }

  private static get baseConfig(): OpenIdConfiguration {
    return {
      authority: environment.login.configurationUrl,
      redirectUrl: environment.login.redirectUrl,
      postLogoutRedirectUri: environment.login.redirectLogoutUrl,
      scope: environment.login.scope,
      responseType: 'code',
      silentRenew: true,
      useRefreshToken: true,
      disableIatOffsetValidation: true,
      disableIdTokenValidation: true,
      autoUserInfo: false,
      logLevel: LogLevel.None,
      customParamsAuthRequest: {
        [UserSessionIdService.userSessionQueryKey]: UserSessionIdService.getSessionId()
      }
    }
  }
}

