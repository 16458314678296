/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReadingReceipt } from './readingReceipt';
import { DiscrepancyReasonEnum } from './discrepancyReasonEnum';


export interface ReadingWithDiscrepancyResponse { 
    receiptId?: string;
    imageIds?: Array<string> | null;
    discrepancyReasons?: Array<DiscrepancyReasonEnum> | null;
    otherReason?: string | null;
    receiptContent?: ReadingReceipt;
}

