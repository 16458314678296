
import { Injectable } from '@angular/core'
import { Logger } from '../utils/logger'
import { EvidaEvent } from './evida-events'
import { GoogleAnalytics } from './google-analytics'

export enum Channel {
  none = 'none',
  sms = 'sms',
  email1 = 'email1',
  email2 = 'email2',
  email3 = 'email3',
  email4 = 'email4'
}

export const ChannelEnumValues: Channel[] =
[
  Channel.none,
  Channel.sms,
  Channel.email1,
  Channel.email2,
  Channel.email3,
  Channel.email4
]

@Injectable({
  providedIn: 'root',
})
export class ChannelTracking {
  private trackedEvents: string[] = []
  private _channel: string

  constructor(private log: Logger) { }

  public get channel(): string | undefined {
    return this._channel
  }
  public set channel(channel: string) {
    this._channel = channel
  }

  public setChannelFromLocation(channel: string | null | undefined) {
    this.channel = ChannelEnumValues.includes(channel as Channel) ? channel : Channel.none
  }

  /**
   * Track the channel information to GA
   * eventName should contain a 'channel' placeholder,
   * which will be substituded with the channel user have entered the site from
   *
   * @param  {string} eventName
   */
  public trackChannel(eventName: string) {
    const result = EvidaEvent.format(eventName, { key: 'channel', value: this.channel })
    if (result === eventName) {
      this.log.warn(`Eventname "${eventName}" does not have the required placeholder "{channel}"`)
    } else {
      if (this.trackedEvents.includes(result)) {
        this.log.warn(`Logging of ${result} has already been done.`)
      } else {
        GoogleAnalytics.trackEvent(result)
        this.trackedEvents.push(result)
      }
    }
  }
}
