import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'commaSeparated'
})
export class CommaSeparatedPipe implements PipeTransform {

  transform(value: any, args?: any): string {
    const separator = args?.separator ?? ', '
    const stringRepresentations = Array.from(value).map(item => item.toString())
    return stringRepresentations.join(separator)
  }

}
