<div class="flex flex-col mt-medium">
  <div class="px-medium header-small">{{ title }}</div>
  <div class="rounded bg-petroleum5pct px-medium py-medium mt-20px">
    <div class="small" *ngIf="description">{{ description }}</div>
    <ng-content></ng-content>
    <div class="flex flex-row w-full justify-center mt-20px" *ngIf="showButton">
      <app-evida-button class="w-full sm:w-auto" type="primary" (clicked)="clicked.emit()">
        {{ buttonText }}
      </app-evida-button>
    </div>
  </div>
</div>
