/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type GrantEligibilityStatus = 'Eligible' | 'NotEligibleDueToConsumption' | 'NotEligibleDueToCvr' | 'NotEligibleNoMoreGrants' | 'NotEligibleAlreadyTerminated' | 'NotEligibleBackOffice' | 'NotEligibleAlreadyTerminatedBeforeGrant' | 'NotEligibleDueToDeminimis';

export const GrantEligibilityStatus = {
    Eligible: 'Eligible' as GrantEligibilityStatus,
    NotEligibleDueToConsumption: 'NotEligibleDueToConsumption' as GrantEligibilityStatus,
    NotEligibleDueToCvr: 'NotEligibleDueToCvr' as GrantEligibilityStatus,
    NotEligibleNoMoreGrants: 'NotEligibleNoMoreGrants' as GrantEligibilityStatus,
    NotEligibleAlreadyTerminated: 'NotEligibleAlreadyTerminated' as GrantEligibilityStatus,
    NotEligibleBackOffice: 'NotEligibleBackOffice' as GrantEligibilityStatus,
    NotEligibleAlreadyTerminatedBeforeGrant: 'NotEligibleAlreadyTerminatedBeforeGrant' as GrantEligibilityStatus,
    NotEligibleDueToDeminimis: 'NotEligibleDueToDeminimis' as GrantEligibilityStatus
};

