<app-evida-error [showCorrelationId]="false" [showRetryButton]="false" icon="evida_error_expired-session" >
  <div>
      <h2 translate="shared.errormessages.status403.title"></h2>
      <div class="mb20" [innerHTML]="'shared.errormessages.status403.description' | translate"></div>
      <div class="mb10">
          <app-evida-button type="primary" (clicked)="loginPage()">{{ 'shared.errormessages.status403.button' | translate }}
          </app-evida-button>
      </div>
  </div>
</app-evida-error>
