<app-loader *ngIf="loading"></app-loader>

<div>
    <div [ngClass]="{ 'mb-medium' : title }">
        <h2 *ngIf="title" class="header-medium mt-medium mb-10px" [translate]="title | translate"></h2>
        <h4 *ngIf="subTitle" class="sub-title" [ngClass]="headerClass" [innerHTML]="subTitle | translate"></h4>
    </div>

    <app-info-message *ngIf="message" class="flex mb-medium" [hint]="message"></app-info-message>

    <mat-card class="mat-elevation-z1" [ngClass]="{ 'hide-background': hideBackground }">
        <div *ngIf="header || body" class="mb-30px">
            <h3 *ngIf="header" class="mb-medium" [translate]="header | translate"></h3>
            <span *ngIf="body" class="sm:body-small body-copy " [innerHTML]="body | translate"></span>
        </div>

        <mat-card-content class="w-full mx-auto">
            <ng-content></ng-content>
        </mat-card-content>
    </mat-card>
</div>