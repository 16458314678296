/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductionType } from './productionType';


export interface ProductionReading { 
    readingDate: string;
    installationNumber: string;
    type: ProductionType;
    production: number;
    gasConsumption?: number | null;
    efficiency?: number | null;
    settled: boolean;
}

