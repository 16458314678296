import { HttpClient } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { Router } from '@angular/router'
import { AuthModule, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client'
import { catchError, EMPTY, mapTo } from 'rxjs'
import { environment } from 'src/environments/environment'
import { LoginRoutes } from '../models/login-routes.model'
import { Configuration } from './configuration.model'

export const httpLoaderFactory = (httpClient: HttpClient, router: Router) => {
  sessionStorage.removeItem('tokenresponse')

  const configuration$ = httpClient.get<any>(environment.login.configurationUrl).pipe(
    catchError(() => {
      router.navigate([LoginRoutes.error])
      return EMPTY
    })
  )

  const businessConfig$ = configuration$.pipe(
    mapTo(Configuration.businessConfig)
  )

  const privateConfig$ = configuration$.pipe(
    mapTo(Configuration.privateConfig)
  )

  return new StsConfigHttpLoader([privateConfig$, businessConfig$])
}

@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient, Router],
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule { }
