<ul class="accordion">
  <!-- Mainmenu item -->
  <li *ngFor="let item of menuService.menuItems$ | async" [class.active]="item.active"
    [ngClass]="{ 'has-submenu' : item.submenu }">
    <div class="menu flex-row mr-15px" (click)="onMenuClick(item)" [class.selected]="item.selected">
      <div class="flex flex-col align-middle mt-auto mr-20px ml-20px mb-auto">
        <mat-icon *ngIf="item.icon" [svgIcon]="item.selected || item.active ? item.selectedIcon : item.icon"></mat-icon>
      </div>

      <div class="flex-col flex-1" [translate]="item.title"></div>
      <div class="arrow flex-col align-middle mr-10px mt-auto mb-auto">
        <mat-icon *ngIf="item.submenu" svgIcon="evida_menu_arrow"></mat-icon>
      </div>
    </div>

    <!-- Submenu items -->
    <ul #submenu *ngIf="item.submenu" class="submenu" [class.active]="submenu.selected"
      [style.height.px]="item.selected ? item.calculatedHeight : 0">
      <ng-container *ngFor="let submenu of item.submenu">
        <li *ngIf="!submenu.hidden" [class.selected]="submenu.selected">
          <a class="mr-10px" [class.selected]="submenu.selected" (click)="onSubmenuClick(submenu)">
            <span class="ml-10" [translate]="submenu.title"></span></a>
        </li>
      </ng-container>
    </ul>
  </li>
   
  <!-- Cancel gas supply button -->
  <span class="link flex body-header fg-green" [routerLink]="['/flow/readings']" translate="menu.cancelGasSupply"></span>
</ul>
