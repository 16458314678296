/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReadingCardLine } from './readingCardLine';
import { ReadingType } from './readingType';
import { ReadingUrgency } from './readingUrgency';
import { PreviousReading } from './previousReading';


export interface ReadingCard { 
    cardNumber: string;
    readingDate: string;
    type: ReadingType;
    urgency: ReadingUrgency;
    cardData: string;
    /**
     * Fee for late readings incl VAT
     */
    lateFee: number;
    lines: Array<ReadingCardLine>;
    /**
     * If the supplier change text should be shown to the customer receiving this reading card
     */
    showSupplierChangeText: boolean;
    previousReading?: PreviousReading;
}

