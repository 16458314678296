/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MonthlyReadingReceiptLine { 
    installationNumber: string;
    meterNumber: string;
    /**
     * Only present for production equipment
     */
    meterLocation?: string | null;
    reading: number;
    consumption: number;
    previousReading: number;
    /**
     * Previous Consumption might not always be available  If no older resolved reading cards can be found, previous consumption cannot be shown
     */
    previousConsumption?: number | null;
    unit: string;
}

