import { Injectable } from '@angular/core'
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms'

@Injectable()
export class Patterns {
  public cprPattern: any = { q: { pattern: new RegExp('[0-9X]') }, 0: { pattern: new RegExp('[0-9]') } }

  public zipCodePattern = /^[0-9]{4}$/g
  public verificationCodePattern = /^[0-9]{6}$/g
  public customerNumberPattern = /^[0-9]{0,10}$/g
  public cprFormat: any = ['000000-qqqq', this.cprPattern]

  // eslint-disable-next-line max-len
  public emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  public numbersPattern = /^[0-9]*$/

  public eanNumberPattern =/^[0-9]{13}$/g

  public get zipCodeValidator(): ValidatorFn {
    return (ctrl: AbstractControl) => {
      const hasMatch = this.zipCodePattern.test(`${ctrl.value}`)
      return hasMatch ? null : { invalidZipcode: `${ctrl.value} does not contain 4 numbers` }
    }
  }

  public get verificationCodeValidator(): ValidatorFn {
    return (ctrl: AbstractControl) => {
      const hasMatch = this.verificationCodePattern.test(`${ctrl.value}`)
      return hasMatch ? null : { invalidCode: `${ctrl.value} does not contain 6 numbers` }
    }
  }

  public get customerNumberValidator(): ValidatorFn {
    return (ctrl: AbstractControl) => {
      if (ctrl.validator?.length) {
        const validator = ctrl.validator({} as AbstractControl)
        if (validator && !validator.required && ctrl.value?.length === 0) {
          return null
        }
      }
      const hasMatch = this.customerNumberPattern.test(`${ctrl.value}`)
      return hasMatch ? null : { invalidNumber: `${ctrl.value} does not contain 0-10 numbers` }
    }
  }

  public get phoneNumberValidator(): ValidatorFn {
    return (ctrl: AbstractControl) => {
      const { countryCode, phoneNumber } = ctrl.value
      // AKA countryCode has a value and phoneNumber has a value
      const isValid = !!countryCode && !!phoneNumber
      return isValid ? null : { invalidPhoneNumber: 'We\'re missing either the country code or the phone number' }
    }
  }

  public phoneNumberValidatorForCountryCode(countryCode: string): ValidatorFn[] {
    // Add more validators for other country codes if needed
    const validators = {
      45: [Validators.minLength(8)],
    }
    return validators[countryCode] ?? []
  }

  public get eanNumberValidator(): ValidatorFn{
    return (ctrl: AbstractControl) => {
      const hasMatch = this.eanNumberPattern.test(`${ctrl.value}`)
      return hasMatch ? null : { invalidEan: `${ctrl.value} does not contain 13 numbers` }
    }
  }
}
