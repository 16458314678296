/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ContactMeBusinessRequest { 
    streetName: string;
    zipCode: string;
    city: string;
    customerNumber?: string | null;
    ownName: string;
    ownEmail: string;
    ownPhoneCountryCode?: string | null;
    ownPhone?: string | null;
    /**
     * Must always be set
     */
    consent?: boolean;
    companyName: string;
}

