/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { Contract } from './contract';


export interface MyInstallationItem { 
    customerNumber: string;
    companyId: string;
    installationNumber: string;
    meterNumber?: string | null;
    contract: Contract;
    address: Address;
}

