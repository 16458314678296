import { Component } from '@angular/core'
import { VerticalMenuComponent } from '../vertical-menu.component'

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss'],
})

export class BurgerMenuComponent extends VerticalMenuComponent {}
