/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MeterReading } from './meterReading';


export interface TerminationReadingRequest { 
    /**
     * Data blob containing information received from card overview
     */
    data: string;
    /**
     * Readinglines for the final reading
     */
    meterReadings: Array<MeterReading>;
}

