import { Injectable } from '@angular/core'


const PHONE_MASKS = {
  45: '00 00 00 00',
  default: '000000000000000000'
}


@Injectable()
export class Masks {

  getMaskForCountryCode(countryCode: string): string {
    return PHONE_MASKS[countryCode] ?? PHONE_MASKS.default
  }
}
