/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GrantEligibilityStatus } from './grantEligibilityStatus';


export interface GrantEligibilityResponse { 
    /**
     * Data which must be passed to request-signature-details endpoint
     */
    grantEligibilityData?: string | null;
    /**
     * The price in DKK for termination without a grant
     */
    areaPriceWithoutGrant?: number;
    grantEligibility?: GrantEligibilityStatus;
}

