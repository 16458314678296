import { Component, Input } from '@angular/core'
import { GoogleAnalytics } from 'src/app/tracking/google-analytics'

import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'
import { EvidaEvents } from 'src/app/tracking/evida-events'

interface EvidaFaqLink {
  text: string;
  href: string;
}
@Component({
  selector: 'app-evida-faq',
  templateUrl: './evida-faq.component.html',
  styleUrls: ['./evida-faq.component.scss'],
})
export class EvidaFaqComponent {
  @Input() public title: string
  public faqLinks$: Observable<EvidaFaqLink[]>

  constructor(private translate: TranslateService) { }

  @Input() public set links(v: string) {
    if (v) {
      this.faqLinks$ = this.translate.get(v)
    }
  }

  public onLinkClick(link: EvidaFaqLink) {
    GoogleAnalytics.trackLink(EvidaEvents.FAQ.clicks.link, link)
  }
}
