export interface CountryCode {
  value: string;
  viewValue: string;
  country: string;
  mask?: string;
}

export const countryCodes: CountryCode[] = [
  {
    value: '1',
    viewValue: '+1',
    country: 'USA',
  },
  {
    value: '7',
    viewValue: '+7',
    country: 'Rusland',
  },
  {
    value: '9',
    viewValue: '+9',
    country: 'Bahrain',
  },
  {
    value: '20',
    viewValue: '+20',
    country: 'Egypten',
  },
  {
    value: '27',
    viewValue: '+27',
    country: 'Sydafrika',
  },
  {
    value: '30',
    viewValue: '+30',
    country: 'Grækenland',
  },
  {
    value: '31',
    viewValue: '+31',
    country: 'Holland',
  },
  {
    value: '32',
    viewValue: '+32',
    country: 'Belgien',
  },
  {
    value: '33',
    viewValue: '+33',
    country: 'Frankrig',
  },
  {
    value: '34',
    viewValue: '+34',
    country: 'Spanien',
  },
  {
    value: '36',
    viewValue: '+36',
    country: 'Ungarn',
  },
  {
    value: '39',
    viewValue: '+39',
    country: 'Italien',
  },
  {
    value: '40',
    viewValue: '+40',
    country: 'Rumænien',
  },
  {
    value: '41',
    viewValue: '+41',
    country: 'Schweiz',
  },
  {
    value: '43',
    viewValue: '+43',
    country: 'Østrig',
  },
  {
    value: '44',
    viewValue: '+44',
    country: 'Storbrittanien',
  },
  {
    value: '45',
    viewValue: '+45',
    country: 'Danmark',
    mask: '00 00 00 00',
  },
  {
    value: '46',
    viewValue: '+46',
    country: 'Sverige',
  },
  {
    value: '47',
    viewValue: '+47',
    country: 'Norge',
  },
  {
    value: '48',
    viewValue: '+48',
    country: 'Polen',
  },
  {
    value: '49',
    viewValue: '+49',
    country: 'Tyskland',
  },
  {
    value: '51',
    viewValue: '+51',
    country: 'Peru',
  },
  {
    value: '52',
    viewValue: '+52',
    country: 'Mexico',
  },
  {
    value: '53',
    viewValue: '+53',
    country: 'Cuba',
  },
  {
    value: '54',
    viewValue: '+54',
    country: 'Argentina',
  },
  {
    value: '55',
    viewValue: '+55',
    country: 'Brasilien',
  },
  {
    value: '56',
    viewValue: '+56',
    country: 'Chile',
  },
  {
    value: '57',
    viewValue: '+57',
    country: 'Colombia',
  },
  {
    value: '58',
    viewValue: '+58',
    country: 'Venezuela',
  },
  {
    value: '60',
    viewValue: '+60',
    country: 'Malaysia',
  },
  {
    value: '61',
    viewValue: '+61',
    country: 'Australien',
  },
  {
    value: '62',
    viewValue: '+62',
    country: 'Indonesien',
  },
  {
    value: '63',
    viewValue: '+63',
    country: 'Filippinerne',
  },
  {
    value: '64',
    viewValue: '+64',
    country: 'New Zealand',
  },
  {
    value: '65',
    viewValue: '+65',
    country: 'Singapore',
  },
  {
    value: '66',
    viewValue: '+66',
    country: 'Thailand',
  },
  {
    value: '81',
    viewValue: '+81',
    country: 'Japan',
  },
  {
    value: '82',
    viewValue: '+82',
    country: 'Sydkorea',
  },
  {
    value: '84',
    viewValue: '+84',
    country: 'Vietnam',
  },
  {
    value: '86',
    viewValue: '+86',
    country: 'Kina',
  },
  {
    value: '90',
    viewValue: '+90',
    country: 'Tyrkiet',
  },
  {
    value: '91',
    viewValue: '+91',
    country: 'Indien',
  },
  {
    value: '92',
    viewValue: '+92',
    country: 'Pakistan',
  },
  {
    value: '93',
    viewValue: '+93',
    country: 'Afghanistan',
  },
  {
    value: '94',
    viewValue: '+94',
    country: 'Sri Lanka',
  },
  {
    value: '98',
    viewValue: '+98',
    country: 'Iran',
  },
  {
    value: '212',
    viewValue: '+212',
    country: 'Marokko',
  },
  {
    value: '213',
    viewValue: '+213',
    country: 'Algeriet',
  },
  {
    value: '216',
    viewValue: '+216',
    country: 'Tunesien',
  },
  {
    value: '218',
    viewValue: '+218',
    country: 'Libyen',
  },
  {
    value: '220',
    viewValue: '+220',
    country: 'Gambia',
  },
  {
    value: '221',
    viewValue: '+221',
    country: 'Senegal',
  },
  {
    value: '222',
    viewValue: '+222',
    country: 'Mauretanien',
  },
  {
    value: '223',
    viewValue: '+223',
    country: 'Mali',
  },
  {
    value: '224',
    viewValue: '+224',
    country: 'Guinea',
  },
  {
    value: '225',
    viewValue: '+225',
    country: 'Elfenbenskysten',
  },
  {
    value: '226',
    viewValue: '+226',
    country: 'Burkina Faso',
  },
  {
    value: '227',
    viewValue: '+227',
    country: 'Niger',
  },
  {
    value: '228',
    viewValue: '+228',
    country: 'Togo',
  },
  {
    value: '229',
    viewValue: '+229',
    country: 'Benin',
  },
  {
    value: '230',
    viewValue: '+230',
    country: 'Mauritius',
  },
  {
    value: '231',
    viewValue: '+231',
    country: 'Liberia',
  },
  {
    value: '232',
    viewValue: '+232',
    country: 'Sierra Leona',
  },
  {
    value: '233',
    viewValue: '+233',
    country: 'Ghana',
  },
  {
    value: '234',
    viewValue: '+234',
    country: 'Nigeria',
  },
  {
    value: '235',
    viewValue: '+235',
    country: 'Tchad',
  },
  {
    value: '236',
    viewValue: '+236',
    country: 'Centralafrikanske Republik',
  },
  {
    value: '238',
    viewValue: '+238',
    country: 'Kap Verde',
  },
  {
    value: '239',
    viewValue: '+239',
    country: 'Sao Tomé og Principe',
  },
  {
    value: '240',
    viewValue: '+240',
    country: 'Ækvatorialguinea',
  },
  {
    value: '241',
    viewValue: '+241',
    country: 'Gabon',
  },
  {
    value: '242',
    viewValue: '+242',
    country: 'Congo',
  },
  {
    value: '242',
    viewValue: '+242',
    country: 'Republikken Congo',
  },
  {
    value: '243',
    viewValue: '+243',
    country: 'Demokratiske Republik Congo',
  },
  {
    value: '244',
    viewValue: '+244',
    country: 'Angola',
  },
  {
    value: '245',
    viewValue: '+245',
    country: 'Guinea-Bissau',
  },
  {
    value: '246',
    viewValue: '+246',
    country: 'Diego Garcia',
  },
  {
    value: '247',
    viewValue: '+247',
    country: 'Acension',
  },
  {
    value: '248',
    viewValue: '+248',
    country: 'Seychellerne',
  },
  {
    value: '249',
    viewValue: '+249',
    country: 'Sudan',
  },
  {
    value: '250',
    viewValue: '+250',
    country: 'Rwanda',
  },
  {
    value: '251',
    viewValue: '+251',
    country: 'Etiopien',
  },
  {
    value: '252',
    viewValue: '+252',
    country: 'Somalia',
  },
  {
    value: '253',
    viewValue: '+253',
    country: 'Djibouti',
  },
  {
    value: '254',
    viewValue: '+254',
    country: 'Kenya',
  },
  {
    value: '255',
    viewValue: '+255',
    country: 'Tanzania',
  },
  {
    value: '256',
    viewValue: '+256',
    country: 'Uganda',
  },
  {
    value: '257',
    viewValue: '+257',
    country: 'Burundi',
  },
  {
    value: '258',
    viewValue: '+258',
    country: 'Mozambique',
  },
  {
    value: '260',
    viewValue: '+260',
    country: 'Zambia',
  },
  {
    value: '261',
    viewValue: '+261',
    country: 'Madagaskar',
  },
  {
    value: '262',
    viewValue: '+262',
    country: 'Mayotte',
  },
  {
    value: '262',
    viewValue: '+262',
    country: 'Réunion',
  },
  {
    value: '263',
    viewValue: '+263',
    country: 'Zimbabwe',
  },
  {
    value: '264',
    viewValue: '+264',
    country: 'Namibia',
  },
  {
    value: '265',
    viewValue: '+265',
    country: 'Malawi',
  },
  {
    value: '266',
    viewValue: '+266',
    country: 'Lesotho',
  },
  {
    value: '267',
    viewValue: '+267',
    country: 'Botswana',
  },
  {
    value: '268',
    viewValue: '+268',
    country: 'Swaziland',
  },
  {
    value: '269',
    viewValue: '+269',
    country: 'Comorerne',
  },
  {
    value: '290',
    viewValue: '+290',
    country: 'Saint Helena',
  },
  {
    value: '291',
    viewValue: '+291',
    country: 'Eritrea',
  },
  {
    value: '297',
    viewValue: '+297',
    country: 'Aruba',
  },
  {
    value: '298',
    viewValue: '+298',
    country: 'Færøerne',
  },
  {
    value: '299',
    viewValue: '+299',
    country: 'Grønland',
  },
  {
    value: '350',
    viewValue: '+350',
    country: 'Gibraltar',
  },
  {
    value: '351',
    viewValue: '+351',
    country: 'Portugal',
  },
  {
    value: '352',
    viewValue: '+352',
    country: 'Luxembourg',
  },
  {
    value: '353',
    viewValue: '+353',
    country: 'Irland',
  },
  {
    value: '354',
    viewValue: '+354',
    country: 'Island',
  },
  {
    value: '355',
    viewValue: '+355',
    country: 'Albanien',
  },
  {
    value: '356',
    viewValue: '+356',
    country: 'Malta',
  },
  {
    value: '358',
    viewValue: '+358',
    country: 'Finland',
  },
  {
    value: '359',
    viewValue: '+359',
    country: 'Bulgarien',
  },
  {
    value: '370',
    viewValue: '+370',
    country: 'Litauen',
  },
  {
    value: '371',
    viewValue: '+371',
    country: 'Letland',
  },
  {
    value: '372',
    viewValue: '+372',
    country: 'Estland',
  },
  {
    value: '373',
    viewValue: '+373',
    country: 'Moldova',
  },
  {
    value: '374',
    viewValue: '+374',
    country: 'Armenien',
  },
  {
    value: '375',
    viewValue: '+375',
    country: 'Hviderusland',
  },
  {
    value: '376',
    viewValue: '+376',
    country: 'Andorra',
  },
  {
    value: '377',
    viewValue: '+377',
    country: 'Monaco',
  },
  {
    value: '378',
    viewValue: '+378',
    country: 'San Marino',
  },
  {
    value: '380',
    viewValue: '+380',
    country: 'Ukraine',
  },
  {
    value: '381',
    viewValue: '+381',
    country: 'Serbien',
  },
  {
    value: '382',
    viewValue: '+382',
    country: 'Montenegro',
  },
  {
    value: '385',
    viewValue: '+385',
    country: 'Kroatien',
  },
  {
    value: '386',
    viewValue: '+386',
    country: 'Slovenien',
  },
  {
    value: '387',
    viewValue: '+387',
    country: 'Bosnien-Hercegovina',
  },
  {
    value: '389',
    viewValue: '+389',
    country: 'Makedonien',
  },
  {
    value: '420',
    viewValue: '+420',
    country: 'Tjekkiet',
  },
  {
    value: '421',
    viewValue: '+421',
    country: 'Slovakiet',
  },
  {
    value: '423',
    viewValue: '+423',
    country: 'Liechtenstein',
  },
  {
    value: '500',
    viewValue: '+500',
    country: 'Falklandsøerne',
  },
  {
    value: '501',
    viewValue: '+501',
    country: 'Belize',
  },
  {
    value: '502',
    viewValue: '+502',
    country: 'Guatemala',
  },
  {
    value: '503',
    viewValue: '+503',
    country: 'El Salvador',
  },
  {
    value: '504',
    viewValue: '+504',
    country: 'Honduras',
  },
  {
    value: '505',
    viewValue: '+505',
    country: 'Nicaragua',
  },
  {
    value: '506',
    viewValue: '+506',
    country: 'Costa Rica',
  },
  {
    value: '507',
    viewValue: '+507',
    country: 'Panama',
  },
  {
    value: '508',
    viewValue: '+508',
    country: 'Saint Pierre og Miquelon',
  },
  {
    value: '509',
    viewValue: '+509',
    country: 'Haiti',
  },
  {
    value: '590',
    viewValue: '+590',
    country: 'Guadeloupe',
  },
  {
    value: '591',
    viewValue: '+591',
    country: 'Bolivia',
  },
  {
    value: '592',
    viewValue: '+592',
    country: 'Guyana',
  },
  {
    value: '593',
    viewValue: '+593',
    country: 'Ecuador',
  },
  {
    value: '594',
    viewValue: '+594',
    country: 'Fransk Guyana',
  },
  {
    value: '595',
    viewValue: '+595',
    country: 'Paraguay',
  },
  {
    value: '596',
    viewValue: '+596',
    country: 'Martinique',
  },
  {
    value: '597',
    viewValue: '+597',
    country: 'Surinam',
  },
  {
    value: '598',
    viewValue: '+598',
    country: 'Uruguay',
  },
  {
    value: '599',
    viewValue: '+599',
    country: 'Nederlandske Antiller',
  },
  {
    value: '619',
    viewValue: '+619',
    country: 'Cocosøen',
  },
  {
    value: '619',
    viewValue: '+619',
    country: 'Juleøen',
  },
  {
    value: '670',
    viewValue: '+670',
    country: 'Østtimor',
  },
  {
    value: '672',
    viewValue: '+672',
    country: 'Australske ekst. områder',
  },
  {
    value: '673',
    viewValue: '+673',
    country: 'Brunei',
  },
  {
    value: '674',
    viewValue: '+674',
    country: 'Nauru',
  },
  {
    value: '675',
    viewValue: '+675',
    country: 'Papua Ny Guinea',
  },
  {
    value: '676',
    viewValue: '+676',
    country: 'Tonga',
  },
  {
    value: '677',
    viewValue: '+677',
    country: 'Salomonøerne',
  },
  {
    value: '678',
    viewValue: '+678',
    country: 'Vanuatu',
  },
  {
    value: '679',
    viewValue: '+679',
    country: 'Fiji',
  },
  {
    value: '681',
    viewValue: '+681',
    country: 'Wallis- og Futunaøerne',
  },
  {
    value: '682',
    viewValue: '+682',
    country: 'Cookøerne',
  },
  {
    value: '683',
    viewValue: '+683',
    country: 'Niue',
  },
  {
    value: '685',
    viewValue: '+685',
    country: 'Vest Samoa',
  },
  {
    value: '686',
    viewValue: '+686',
    country: 'Kiribati',
  },
  {
    value: '687',
    viewValue: '+687',
    country: 'Ny Caledonien',
  },
  {
    value: '688',
    viewValue: '+688',
    country: 'Tuvalu',
  },
  {
    value: '689',
    viewValue: '+689',
    country: 'Fransk Polynesien',
  },
  {
    value: '691',
    viewValue: '+691',
    country: 'Mikronesien',
  },
  {
    value: '692',
    viewValue: '+692',
    country: 'Marshalløerne',
  },
  {
    value: '850',
    viewValue: '+850',
    country: 'Nordkorea',
  },
  {
    value: '852',
    viewValue: '+852',
    country: 'Hong Kong',
  },
  {
    value: '853',
    viewValue: '+853',
    country: 'Macau',
  },
  {
    value: '856',
    viewValue: '+856',
    country: 'Laos',
  },
  {
    value: '880',
    viewValue: '+880',
    country: 'Bangladesh',
  },
  {
    value: '886',
    viewValue: '+886',
    country: 'Taiwan',
  },
  {
    value: '960',
    viewValue: '+960',
    country: 'Maldiverne',
  },
  {
    value: '961',
    viewValue: '+961',
    country: 'Libanon',
  },
  {
    value: '962',
    viewValue: '+962',
    country: 'Jordan',
  },
  {
    value: '963',
    viewValue: '+963',
    country: 'Syrien',
  },
  {
    value: '964',
    viewValue: '+964',
    country: 'Irak',
  },
  {
    value: '965',
    viewValue: '+965',
    country: 'Kuwait',
  },
  {
    value: '966',
    viewValue: '+966',
    country: 'Saudi-Arabien',
  },
  {
    value: '967',
    viewValue: '+967',
    country: 'Yemen',
  },
  {
    value: '968',
    viewValue: '+968',
    country: 'Oman',
  },
  {
    value: '971',
    viewValue: '+971',
    country: 'Forenede Arabiske Emirater',
  },
  {
    value: '972',
    viewValue: '+972',
    country: 'Israel',
  },
  {
    value: '974',
    viewValue: '+974',
    country: 'Qatar',
  },
  {
    value: '975',
    viewValue: '+975',
    country: 'Bhutan',
  },
  {
    value: '976',
    viewValue: '+976',
    country: 'Mongoliet',
  },
  {
    value: '977 ',
    viewValue: '+977 ',
    country: 'Nepal',
  },
  {
    value: '992',
    viewValue: '+992',
    country: 'Tadsjikistan',
  },
  {
    value: '993',
    viewValue: '+993',
    country: 'Turkmenistan',
  },
  {
    value: '994',
    viewValue: '+994',
    country: 'Aserbajdsjan',
  },
  {
    value: '995',
    viewValue: '+995',
    country: 'Geogien',
  },
  {
    value: '996',
    viewValue: '+996',
    country: 'Kirgisistan',
  },
]
