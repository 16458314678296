/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ReadingType = 'Normal' | 'SupplierChange' | 'Control' | 'TerminationReading' | 'MovingOut';

export const ReadingType = {
    Normal: 'Normal' as ReadingType,
    SupplierChange: 'SupplierChange' as ReadingType,
    Control: 'Control' as ReadingType,
    TerminationReading: 'TerminationReading' as ReadingType,
    MovingOut: 'MovingOut' as ReadingType
};

