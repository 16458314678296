import { Location } from '@angular/common'
import { Component } from '@angular/core'

@Component({
  selector: 'app-no-access-error',
  templateUrl: './no-access-error.component.html',
  styleUrls: ['./no-access-error.component.scss']
})
export class NoAccessErrorComponent {
  constructor(private location: Location){}

  public onGoBack() {
    this.location.back()
  }
}
