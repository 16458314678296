/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents all the data needed for a single reading line
 */
export interface MeterReading { 
    /**
     * Xellent meter number
     */
    meterNumber: string;
    /**
     * Meter value
     */
    meterValue: number;
    /**
     * 0..n image ids to document the reading
     */
    imageIds: Array<string>;
}

