import { Address } from 'src/app/shared/services/backend/model/address'
import { Utils } from 'src/app/utils/utils'

export class AddressMapper {
  static getFullAddress(addressLike: { line1: string; line2: string } | null | undefined): string {
    const addressStrings = [
      Utils.emptyIfNull(addressLike?.line1),
      Utils.emptyIfNull(addressLike?.line2),
      // Get rid of empty strings
    ].filter((str) => str.length > 0)
    return addressStrings.join(', ').trim()
  }

  static formatAddress(address: Address | null, withoutZipAndCity: boolean = false): string {
    const addressStrings = [
      Utils.emptyIfNull(address?.street),
      Utils.emptyIfNull(address?.houseNumber),
      Utils.emptyIfNull(address?.houseLetter),
      Utils.emptyIfNull(address?.floorNumber),
      Utils.emptyIfNull(address?.apartment),
    ].filter((str) => str.length > 0).join(' ').trim()

    const zipCodeCityStrings = [
      Utils.emptyIfNull(address?.zipcode),
      Utils.emptyIfNull(address?.city),
    ].filter((str) => str.length > 0).join(' ').trim()

    return withoutZipAndCity ? addressStrings : `${addressStrings}, ${zipCodeCityStrings}`
  }

  static formatAddressFromString(address: string | null): { line1: string; line2: string } {
    if (address) {
      const split = address.split(',')
      const lastValue = split.pop()
      const restValues = split.join(', ')
      return {
        line1:`${Utils.emptyIfNull(restValues)}`,
        line2: `${Utils.emptyIfNull(lastValue)}`
      }
    }
    return {line1: '', line2: ''}
  }

  static formatAddressAsTwoLinesHtml(address: Address): string {
    const addressAsString = AddressMapper.formatAddress(address)
    const { line1, line2 } = AddressMapper.formatAddressFromString(addressAsString)
    return `<span>${line1}</span><span>${line2}</span>`
  }
}
