<div [ngClass]="{ disabled: disabled }">
  <form [formGroup]="phoneNumberFormGroup">
    <div>
      <strong>{{ label | translate }}<span class="required" *ngIf="required">*</span></strong>
    </div>

    <div class="mt-12px flex">
      <mat-form-field appearance="outline" class="country-code mr10">
        <mat-select formControlName="countryCode" (selectionChange)="countryCodeChanged($event.value)">
          <mat-option *ngFor="let countryCode of countryCodes" [value]="countryCode.value">
            {{ countryCode.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full phoneNumber">
        <input type="text" matInput formControlName="phoneNumber" [placeholder]="phoneNumberPlaceholder | translate"
          [mask]="maskForCountryCode" (change)="phoneNumberChanged($event.srcElement.value)">
        <mat-error class="body-small-header" *ngIf="!phoneNumberFormGroup.controls.phoneNumber.valid">{{
          invalidPhoneNumberText | translate
          }}</mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
