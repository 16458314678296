/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MeterReadingValidationStatus = 'Valid' | 'Invalid';

export const MeterReadingValidationStatus = {
    Valid: 'Valid' as MeterReadingValidationStatus,
    Invalid: 'Invalid' as MeterReadingValidationStatus
};

