import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'

import { Injectable } from '@angular/core'
import { ChannelTracking } from 'src/app/tracking/channel-tracking'
import { Features } from '../models/features.model'

@Injectable({
  providedIn: 'root',
})
export default class ChannelGuard  {

  constructor(private channelTracking: ChannelTracking, private router: Router, private features: Features) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    if(this.features.enableMaintenance ){
      this.router.navigate(['/'])
      return false
    }

    const channel = decodeURIComponent(route.queryParamMap.get('channel'))?.toLowerCase()
    this.channelTracking.setChannelFromLocation(channel)
    return true
  }
}
