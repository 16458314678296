export * from './analytics.service';
import { AnalyticsService } from './analytics.service';
export * from './annualReading.service';
import { AnnualReadingService } from './annualReading.service';
export * from './businessProfile.service';
import { BusinessProfileService } from './businessProfile.service';
export * from './consent.service';
import { ConsentService } from './consent.service';
export * from './development.service';
import { DevelopmentService } from './development.service';
export * from './expectedConsumption.service';
import { ExpectedConsumptionService } from './expectedConsumption.service';
export * from './health.service';
import { HealthService } from './health.service';
export * from './installations.service';
import { InstallationsService } from './installations.service';
export * from './invoice.service';
import { InvoiceService } from './invoice.service';
export * from './monthlyReading.service';
import { MonthlyReadingService } from './monthlyReading.service';
export * from './privateProfile.service';
import { PrivateProfileService } from './privateProfile.service';
export * from './productionConsumption.service';
import { ProductionConsumptionService } from './productionConsumption.service';
export * from './reading.service';
import { ReadingService } from './reading.service';
export * from './registeredConsumption.service';
import { RegisteredConsumptionService } from './registeredConsumption.service';
export * from './terminationReading.service';
import { TerminationReadingService } from './terminationReading.service';
export * from './terminations.service';
import { TerminationsService } from './terminations.service';
export const APIS = [AnalyticsService, AnnualReadingService, BusinessProfileService, ConsentService, DevelopmentService, ExpectedConsumptionService, HealthService, InstallationsService, InvoiceService, MonthlyReadingService, PrivateProfileService, ProductionConsumptionService, ReadingService, RegisteredConsumptionService, TerminationReadingService, TerminationsService];
