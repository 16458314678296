/**
 * MitEvida API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ForgotResponse { 
    /**
     * Challenge to provide to backend with the code received via separate channel
     */
    challenge?: string | null;
    /**
     * Time in seconds the customer has to complete the forgot details request.
     */
    lifetimeSeconds?: number;
}

