import { Component, EventEmitter, Input, Output } from '@angular/core'

export type EvidaButtonType =
  | 'back'
  | 'forward'
  | 'round'
  | 'primary'
  | 'secondary'
  | 'secondary_orange'
  | 'tertiary'
  | 'quaternary'
  | 'toolbar'
  | 'info';

export const EvidaButtonTypes: string[] = [
  'back',
  'forward',
  'round',
  'primary',
  'secondary',
  'secondary_orange',
  'tertiary',
  'quaternary',
  'toolbar',
  'info'
]

@Component({
  selector: 'app-evida-button',
  templateUrl: './evida-button.component.html',
  styleUrls: ['./evida-button.component.scss'],
})
export class EvidaButtonComponent {
  @Input() public tabindex = 0
  @Input() public disabled = false
  @Input() public type: EvidaButtonType
  @Input() public iconPrefixId: string | undefined = undefined
  @Input() public iconSuffixId: string | undefined = undefined
  @Output() public clicked = new EventEmitter<null>()

  constructor() { }
}
