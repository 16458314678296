import { BreakpointObserver } from '@angular/cdk/layout'
import { Component, Input } from '@angular/core'
import { map } from 'rxjs/operators'
import { FlowMessages } from 'src/app/soft-login/base-reading'
import { Breakpoints } from 'src/app/utils/breakpoints'

@Component({
  selector: 'app-reading-step',
  templateUrl: './reading-step.component.html',
  styleUrls: ['./reading-step.component.scss']
})
export class ReadingStepComponent {
  @Input() public title: string
  @Input() public description: string
  @Input() public messages: FlowMessages | null = null

  public isXS$ = this.bpo.observe(Breakpoints.xs).pipe(
    map((value) => value.matches)
  )

  constructor(
    private bpo: BreakpointObserver
  ) { }
}
