export interface StepChange {
  previousIndex: number;
  newIndex: number;
}
export type StepChangeListener = (stepChange: StepChange) => void;
export class EvidaStepperController {

  private _stepChangeListener: StepChangeListener | null = null


  private constructor(
    public steps: string[],
    public currentStepIndex: number
  ) { }

  public get currentStep(): string {
    return this.steps[this.currentStepIndex]
  }
  public get stepChangeListener(): StepChangeListener | null {
    return this._stepChangeListener
  }
  public set stepChangeListener(v: StepChangeListener | null) {
    this._stepChangeListener = v
  }

  static default(steps: string[], listener?: StepChangeListener): EvidaStepperController {
    if (steps.length === 0) {
      throw new Error('Invalid arguments at EvidaStepperController.default. 0 steps for stepper')
    }
    const controller = new EvidaStepperController(steps, 0)
    if (listener) {
      controller.stepChangeListener = listener
    }
    return controller
  }

  public setStep(newStep: number): string {
    const listener = this.stepChangeListener
    const previous = this.currentStepIndex
    const isInBounds = (step: number): boolean => (Math.sign(step) >= 0 && step < this.steps.length)
    this.currentStepIndex = isInBounds(newStep) ? newStep : this.currentStepIndex
    const notifyListener = previous !== this.currentStepIndex && listener != null
    if (notifyListener) {
      const stepChange: StepChange = {
        previousIndex: previous,
        newIndex: this.currentStepIndex
      }
      listener(stepChange)
    }
    return this.currentStep
  }

  /**
   * Moves stepper to next step and returns the new step name
   */
  public next(): string {
    this.setStep(
      Math.min(this.currentStepIndex + 1, this.steps.length - 1)
    )
    return this.steps[this.currentStepIndex]
  }

  /**
   * Moves stepper to previous step and returns the new step name
   */
  public previous(): string {
    this.setStep(
      Math.max(this.currentStepIndex - 1, 0)
    )
    return this.currentStep
  }
}
