<div *ngIf="title">
  <div class="title flex flex-row">
    <span class="indicator"></span>
    <h4 class="pb10 body-header" [innerHTML]="title | translate"></h4>
  </div>
  
  <a *ngFor="let link of faqLinks$ | async;" [href]="link.href" title="" target="_blank" class="small" (click)="onLinkClick(link)">
    <div class="inline-grid">
      <span class="indicator"></span>
      <span class="line"></span>
    </div>
    <span class="self-center" [innerHTML]="link.text"></span>
  </a>
</div>
