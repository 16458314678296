import { Injectable } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { BehaviorSubject, distinctUntilChanged, Observable, Subscription } from 'rxjs'
import { DialogComponent } from '../components/dialog/dialog.component'

@Injectable({
  providedIn: 'platform'
})
export class LoadingStateService {
  public dialogRef: MatDialogRef<DialogComponent>
  private _isLoading$ = new BehaviorSubject(false)
  private _previousSubscription = new Subscription()
  public get isLoading$(): Observable<boolean> {
    return this._isLoading$.asObservable().pipe(distinctUntilChanged())
  }

  public setLoading() {
    this._isLoading$.next(true)
  }

  public setNotLoading() {
    this.closeDialog()
    this._isLoading$.next(false)
  }

  public feedFrom(otherObservable: Observable<boolean>) {
    this._previousSubscription.unsubscribe()
    this._previousSubscription = new Subscription()
    this._previousSubscription.add(
      otherObservable.subscribe(val => this._isLoading$.next(val))
    )
  }

  public closeDialog() {
    if (this.dialogRef) {
      this.dialogRef.close()
    }
  }
}
